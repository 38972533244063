@import 'styles';

.address-change-alert-component {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 35px;

  > .icon {
    height: 54px;
    margin-bottom: 24px;
    width: 54px;

    > .green {
      fill: $green;
    }
  }

  > .title {
    color: $black;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 17px;
    text-align: center;
  }

  > .description {
    color: $boulder;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
    text-align: center;
    white-space: break-spaces;
  }

  .button-text-container {
    align-items: center;
    display: flex;

    > .whats-app-icon {
      margin-right: 6px;
      width: 15px;
    }
  }
}
