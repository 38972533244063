@import 'styles';

$container-option-shadow: rgba(0, 0, 0, 0.1);

.container-confirmation {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > .container-info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    > .container-title {
      display: flex;
      justify-content: center;
      width: 100%;

      > .title {
        color: $black;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 22px;
      }
    }

    > .subtitle {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    > .container-option {
      align-items: flex-start;
      background-color: $white;
      border-radius: 5px;
      border: 1px solid $mercury;
      box-shadow: 0px 2px 4px 0px $container-option-shadow;
      display: flex;
      flex-direction: column;
      height: 70px;
      margin-bottom: 24px;
      padding: 11px;
      width: 100%;

      > .due-date {
        color: $mine-shaft;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      > .payment {
        color: $mine-shaft;
        font-size: 20px;
        font-weight: 700;
      }
    }

    > .description {
      color: $mine-shaft;
      font-size: 16px;
      font-weight: 500;

      &.first {
        margin-bottom: 16px;
      }
    }
  }

  > .container-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    > .button {
      font-size: 16px;
      font-weight: 500;
      height: 38px;

      &.accept {
        margin-bottom: 10px;
      }
    }
  }
}
