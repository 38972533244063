@import 'styles';

.back-button-component {
  color: $primary-color;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin: 0 auto;

  > .icon {
    margin-right: 8px;
  }
}
