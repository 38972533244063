@import 'styles';

.tracking-button-component {
  align-items: center;
  background-color: $primary-color;
  border-radius: 4px;
  color: $white;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  width: 100%;

  &:visited {
    color: $white;
  }
}
