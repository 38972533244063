@import 'styles';

.update-email-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 380px;

  > .round {
    align-items: center;
    background-color: $primary-color-tint;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    > .icon {
      color: $white;
      height: 24px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    margin-top: 8px;
  }

  > .text {
    color: $text-light;
    font-size: 16px;
    margin-top: 8px;
    text-align: center;
  }

  > .button {
    margin-top: 16px;
  }
}
