@import 'styles';

.validate-token-page {
  @include container;

  padding: 16px 8px;

  > .logo {
    width: $password-recovery-logo-width;
  }

  > .alert {
    margin: 8px auto 0;
    width: 100%;
  }

  > .text-container {
    display: flex;
    flex-direction: column;
    margin: $password-recovery-container-margin;
    max-width: $password-recovery-container-width;
    text-align: $password-recovery-text-alignment;

    > .title {
      color: $text;
      font-size: $password-recovery-title-size;
      font-weight: 500;
      line-height: 1.33;
    }

    > .subtitle {
      color: $text-light;
      font-size: $password-recovery-subtitle-size;
      font-weight: normal;
      margin-top: 8px;
      margin-bottom: 30px;
    }

    > .resend-token {
      background-color: $primary-color;
      border-radius: 5px;
      color: $resend-token-text-color;
      margin: 0 auto;
      margin-bottom: 30px;
      padding: 5px;
      width: 250px;
    }

    > .button {
      margin-top: 32px;
    }
  }
}
