@import '../../styles/';

.home-page {
  scroll-behavior: smooth;

  > .main {
    @include container;

    padding: 0 16px 50px;

    > .title {
      color: $text;
      font-size: 14px;
      font-weight: 500;
      margin-top: 18px;

      > .name {
        text-transform: capitalize;
      }
    }

    > .alert {
      margin-top: 12px;
    }

    > .cards {
      margin-top: 16px;

      @include for-tablet-landscape-up {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(4, minmax(152px, auto));
        gap: 24px 32px;
      }

      > .bills,
      > .info,
      > .generic,
      > .address,
      > .genericlarge,
      > .subscription,
      > .phone {
        margin-top: 16px;

        @include for-tablet-landscape-up {
          margin: 0;
        }
      }

      > .bills {
        grid-row: span 2;
        grid-column: span 2;

        .bills-card-loading {
          @include skeleton-animation;
          min-height: 65px;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
        }
      }

      > .info,
      > .address,
      > .genericlarge,
      > .phone,
      > .subscription {
        grid-column: span 2;
      }
    }
  }
}
