@import 'styles';

.my-bills-page {
  @include container;

  padding: 24px 16px 24px;

  > .return {
    color: $text;
    display: flex;
    font-size: 16px;
    font-weight: 600;

    > .icon {
      color: $text-light;
      height: 24px;
      margin-right: 8px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    margin-top: 16px;
  }

  > .card {
    margin-top: 8px;
    min-height: 522px;

    > .tabs {
      border-bottom: 1px solid $light-gray;
      display: flex;
      margin-bottom: 16px;

      @include for-tablet-landscape-up {
        margin-bottom: 64px;
      }

      > .tab {
        color: $text-light;
        flex: 1;
        font-size: 14px;
        line-height: 1.14;
        padding-bottom: 6px;
        position: relative;

        @include for-tablet-landscape-up {
          flex: initial;
          min-width: 192px;
          padding: 16px;
        }

        &.-active {
          color: $text;
          font-weight: 600;

          &:after {
            background-color: $primary-color-tint;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            bottom: 0;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }

    > .empty {
      align-items: center;
      background-color: rgba(242, 242, 242, 0.5);
      border-radius: 4px;
      border: 1px solid $gray;
      color: $text;
      display: inline-flex;
      font-size: 16px;
      font-weight: 500;
      justify-content: center;
      padding: 16px;

      > .icon {
        color: $text;
        flex-shrink: 0;
        height: 40px;
        margin-right: 16px;
        width: 40px;
      }
    }

    > .button {
      color: $primary-color;
      display: block;
      margin: 8px auto 0;
      font-size: 12px;
      font-weight: 500;
    }

    > .clerk-warning {
      color: $text;
      font-size: 12px;
      font-style: italic;
      margin-top: 8px;

      @include for-tablet-portrait-up {
        margin-top: 16px;
      }
    }
  }

  .bills-card-loading {
    @include skeleton-animation;
    min-height: 65px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
}
