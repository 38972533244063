@import '../../styles';

$menu-height: 424px;

.header-component {
  background: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  position: relative;

  > .menu {
    background: white;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    left: 0;
    position: absolute;
    right: 0;
    top: -300vh;
    transition: top 0.3s ease-in-out;
    z-index: 1010;

    &.-open {
      box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.13);
      top: 0;
    }

    > .overlay {
      bottom: -100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 100%;
    }

    > .container {
      @include container;

      padding: 12px;

      @include for-tablet-landscape-up {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      > .close {
        color: $text-color;
        width: 24px;
        height: 24px;
        width: 100%;

        > .icon {
          width: initial;
        }
      }

      > .col {
        @include for-tablet-landscape-up {
          flex: 1;
          padding: 0 8px;
        }

        > .title {
          color: $text-color;
          font-size: 14px;
          font-weight: 600;
          margin-top: 24px;
        }

        > .item {
          margin-top: 8px;
        }

        > .leave {
          margin-top: 32px;
        }
      }
    }
  }

  > .container {
    @include container;

    display: flex;
    justify-content: space-between;
    padding: 12px 12px 12px 8px;

    > .wrapper {
      align-items: center;
      display: flex;

      > .menu {
        color: $menu-hamburguer-icon;
        height: 24px;
        width: 24px;
      }

      > .link {
        > .logo {
          height: $logo-height;
          margin-left: 16px;
        }
      }

      > .select {
        display: none;
        width: 208px;

        @include tablet {
          display: block;
        }
      }

      > .button {
        color: $text;
        display: none;
        font-size: 12px;
        font-weight: 600;
        margin-left: 8px;
        text-transform: uppercase;

        @include tablet {
          margin-left: 32px;
          display: block;
        }

        &.-logout {
          display: block;
        }
      }

      > .notifications {
        color: $notification-color;
        height: 24px;
        margin-left: 32px;
        position: relative;
        width: 24px;

        &.-new {
          &:after {
            align-items: center;
            background: $red;
            border-radius: 6px;
            color: $white;
            content: attr(data-length);
            display: flex;
            font-size: 10px;
            font-weight: 500;
            height: 12px;
            justify-content: center;
            left: 16px;
            padding: 4px;
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }
}
