@import 'styles';

.calls-extract-component {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .icon {
    height: 56px;
    margin-top: 24px;
    margin: 0 auto;
    width: 56px;
  }

  > .message {
    color: $text-light;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    margin-top: 16px;
    text-align: center;
  }

  > .card {
    align-items: center;
    display: flex;
    margin-top: 16px;
    padding: 8px 20px;

    > .period {
      flex: 1;
    }

    > .total {
      flex: 0.4;
    }

    > .download {
      color: $primary-color;
      cursor: pointer;
      flex: 0.2;
      height: 24px;
      width: 24px;
    }

    > .loader {
      flex: 0.2;

      > .img {
        height: 20px;
        margin: 0 auto;
        width: 20px;
      }
    }

    > .period,
    > .total {
      > .title {
        color: $text;
        font-size: 14px;
        font-weight: 500;
      }

      > .text {
        color: $text-light;
        font-size: 12px;
      }
    }

    > .text {
      color: $text;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
    }

    > .date {
      color: $text-light;
      font-size: 12px;
    }

    & + .card {
      margin-top: 8px;
    }
  }
}
