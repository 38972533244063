@import 'styles';

.booking-radio-component {
  align-items: center;
  border-radius: 5px;
  border: 1px solid $mercury;
  box-shadow: 2px 2px 4px rgba($black, 0.15);
  display: flex;
  height: 60px;
  margin-bottom: 16px;
  width: 100%;

  &.selected {
    border-color: $primary-color;
  }

  > .booking-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    height: 60px;
    padding-left: 15px;
    width: 100%;

    > .booking-circle {
      border-radius: 50%;
      border: 1px solid $silver-chalice;
      display: inline-block;
      height: 20px;
      margin: 0 8px 0 0;
      position: relative;
      transition: border-color 0.3s ease-in-out;
      width: 20px;

      &::after {
        background-color: $primary-color;
        border-radius: 50%;
        content: '';
        display: block;
        height: 10px;
        left: 50%;
        margin: -5px 0 0 -5px;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: scale(1);
        transition: 0.3s ease-in-out;
        width: 10px;
      }
    }

    > .booking-input {
      &:checked + .booking-circle {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
