.fast-billet-page {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;

  > .logo {
    margin: 0 auto;
    max-width: 200px;
  }
}
