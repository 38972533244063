@import 'styles';

.tv-plan-component {
  @include for-tablet-portrait-up {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  > .header {
    align-items: center;
    display: flex;
    height: 45px;
    width: 45px;

    @include for-tablet-portrait-up {
      align-items: flex-start;
      margin-bottom: 25px;
    }

    > .image {
      border-radius: 10px;
      height: 45px;
      width: 45px;
    }

    > .title {
      color: $text-color-shade;
      font-size: 16px;
      font-weight: bold;
      margin-left: 16px;

      @include for-tablet-portrait-up {
        display: none;
      }
    }
  }

  > .content {
    @include for-tablet-portrait-up {
      margin-left: 16px;
      flex: 1;
    }

    > .title {
      color: $text-color-shade;
      display: none;
      font-size: 16px;
      font-weight: bold;

      @include for-tablet-portrait-up {
        display: block;
      }
    }

    > .text {
      color: $text;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  > .action {
    align-self: flex-end;
    justify-content: center;
    width: 100%;

    @include for-tablet-portrait-up {
      display: flex;
    }

    > .link {
      > .button {
        color: $action-button-text-color;
        font-size: 14px;
        margin-top: 8px;
        max-width: 158px;
        padding-inline: 5px;
        text-transform: uppercase;

        @include for-tablet-portrait-up {
          align-self: center;
          margin: 0 0 0 8px;
        }
      }
    }
  }
}
