@import '../../styles';

.personal-info-component {
  @include for-tablet-portrait-up {
    display: flex;
  }

  > .wrapper {
    flex: 1;

    > .title {
      color: $text-color-shade;
      font-size: 16px;
    }

    > .name {
      color: $text;
      font-size: 14px;
      font-weight: bold;
      margin-top: 8px;
    }

    > .document {
      color: $text-light;
      font-size: 14px;
      font-weight: 500;
    }

    &.-actions {
      margin-left: 2px;
    }
  }
}
