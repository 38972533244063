@import 'styles';

.protocol-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 40px;

  > .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;

    > .icon {
      color: $primary-color;
      height: 48px;
      padding: 4px;
      width: 48px;
    }
  }
}
