@import 'styles';

.file-upload-whatsapp {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 380px;
  min-height: 205px;
  text-align: center;

  > .round {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    &.-red {
      background-color: $red;
    }
    &.-blue {
      background-color: $primary-color;
    }
    &.-green {
      background-color: $primary-color;
    }

    > .icon {
      color: $white;
      height: 24px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    margin-top: 8px;
  }

  > .text {
    color: $text-light;
    font-size: 16px;

    &:first-of-type {
      margin-top: 8px;
    }
  }

  > .message {
    font-size: 12px;
    margin-top: 16px;

    &.-error {
      color: $red;
    }

    &.-success {
      color: $green;
    }
  }

  > .button {
    margin-top: 16px;
  }

  > .whatsapp-button {
    align-items: center;
    background-color: $primary-color;
    border-radius: 4px;
    color: $base-button-text-color;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 24px auto 0;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    width: 100%;

    &:hover {
      background-color: $white;
      border: 1px solid $primary-color;
      color: $primary-color;
    }

    > .input {
      display: none;
    }

    svg {
      width: 21px;
      height: 21px;
      margin-right: 10px;
    }
  }
}
