@import '../../styles';

.chat-component {
  background-color: $white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  bottom: 0px;
  height: 450px;
  overflow: hidden;
  position: fixed;
  right: auto;
  width: 300px;
  z-index: 99;

  @include for-tablet-landscape-up {
    right: 90px;
  }

  .header {
    background: $primary-color-shade;
    padding: 15px;
    position: relative;
    z-index: 2;

    p {
      color: $white;
      font-size: 14px;
      margin: 0;
    }

    .close {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      border: 0;
      cursor: pointer;
      background: none;

      &::before,
      &::after {
        content: '';
        width: 15px;
        height: 2px;
        background-color: $white;
        position: absolute;
        left: 5px;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.chat-button {
  border: none;
  background: transparent;
  float: right;
  margin: 15px;
  z-index: 1;

  svg {
    fill: $primary-color-shade;
  }
}

#fixed-button {
  bottom: 0px;
  height: 57px;
  position: fixed;
  right: 0px;
  width: 57px;

  @include for-tablet-landscape-up {
    height: 67px;
    width: 67px;
  }
}

.sigma-bi, .bi  {
  height: unset;
  max-width: unset;
  width: unset;
}
