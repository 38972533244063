@import 'styles';

.schedule-confirmation {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 24px;
    }

    > .info-confirmation {
      background: $wild-sand;
      border-radius: 10px;
      padding: 16px 12px;

      > .text {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 400;
      }

      > .container-schedule {
        display: flex;

        justify-content: space-between;
        margin: 25px 0;

        .info-text {
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      > .reference {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        > .text {
          font-size: 16px;
          margin-right: 5px;
        }
      }

      > .alert {
        display: flex;

        .icon-alert {
          color: $notification-color;
          height: 17px;
          margin-right: 3px;
          width: 17px;
        }

        .alert-schedule {
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
  }

  > .links {
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
