@import 'styles';

$discount-text-color: #ff7a00;

.negotiation-options {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 27px 16px 27px 10px;

  > .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  > .description-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;

    > .title {
      font-size: 16px;
      font-weight: 600;
    }

    > .description {
      color: $text-dark;
    }
  }
  > .option-container {
    flex-grow: 1;

    > .option-card {
      align-items: center;
      border-radius: 5px;
      border: 1px solid $mercury;
      box-shadow: 0px 2px 4px 0px $mercury;
      display: flex;
      gap: 16px;
      margin-bottom: 8px;
      padding: 12px;

      > .option-checkbox {
        border-radius: 5px;
        width: 24px;

        &:checked {
          accent-color: $primary-color;
        }
      }

      > .option-info {
        color: $mine-shaft;
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .option-due-date {
          font-size: 14px;
          font-weight: 400;
        }

        > .container-values {
          display: flex;
          width: 100%;

          > .option-value {
            font-size: 20px;
            font-weight: 700;
            margin-right: 48px;
          }

          > .discount {
            align-items: center;
            color: $discount-text-color;
            display: flex;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }

  > .button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > .refuse {
      background-color: $white;
      color: $mine-shaft;
    }

    > .button {
      min-height: 38px;

      &[disabled] {
        color: $text-light;
      }
    }
  }
}
