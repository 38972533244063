@import 'styles';

$check-color: #58af24;

.container-success {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 27px;
  width: 100%;

  > .container-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .container-icon {
      height: 53px;
      margin: 9px 0 24px;
      width: 53px;

      svg {
        fill: $check-color;
      }
    }

    > .title {
      color: $black;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    > .container-description {
      display: flex;
      flex-direction: column;
      gap: 24px;

      > .description {
        color: $text-darker;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  > .container-payments {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 33px;

    > .title {
      color: $primary-color;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    > .container-buttons {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;

      > .button {
        align-items: flex-start;
        background-color: $primary-color;
        border-radius: 12px;
        border: none;
        color: $white;
        display: flex;
        flex-direction: column;
        height: 80px;
        margin-right: 8px;
        width: 80px;
        padding: 16px 8px;

        > .icon {
          margin-bottom: 8px;
        }

        > .text {
          font-size: 10px;
          font-weight: 600;
          text-align: left;
        }
      }
    }
  }

  > .container-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .button {
      font-size: 16px;
      font-weight: 500;
      height: 38px;

      &.accept {
        margin-bottom: 10px;
      }
    }
  }
}
