@import 'styles';

$whatsapp-color: #25d265;

.container-contact {
  bottom: 10px;
  position: fixed;
  right: 15px;
  z-index: 8;

  > .button-container {
    padding-bottom: 5px;
    position: relative;

    > .contact-button {
      align-items: center;
      background-color: $whatsapp-color;
      border-radius: 50%;
      border: 2.5px solid $white;
      bottom: 10px;
      box-shadow: 10px 10px 14px -8px rgba($black, 0.2);
      cursor: pointer;
      display: flex;
      height: 60px;
      width: 60px;
      justify-content: center;
      z-index: 2;

      @include for-tablet-landscape-up {
        height: 70px;
        width: 70px;
      }

      > .image {
        width: 50%;
      }

      > .icon-container {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;

        > .icon {
          fill: $white;
          height: 27px;
          width: 27px;

          @include for-tablet-landscape-up {
            height: 35px;
            width: 35px;
          }
        }
      }
    }
  }
}
