@import 'styles';

.empty-bills-component {
  align-items: center;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 4px;
  border: 1px solid $silver;
  color: $text;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding: 8px;

  > .icon {
    color: $text;
    flex-shrink: 0;
    height: 40px;
    margin-right: 16px;
    width: 40px;
  }
}
