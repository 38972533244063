@import 'styles';

.card-button-component {
  align-items: center;
  color: $text-color-shade;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin: 16px 0 0 auto;
  width: 100%;

  @include for-tablet-portrait-up {
    margin-left: auto;
    max-width: 256px;
  }

  &:first-child {
    @include for-tablet-portrait-up {
      margin-top: 0;
    }
  }

  > .icon {
    border-radius: 50%;
    color: $svg-color;
    margin-right: 10px;
    padding: 4px;
  }

  > .arrow-icon {
    margin-left: auto;
    padding: 0 5px;
  }
}
