@import 'styles';

.modem-success {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > .success {
    align-items: center;
    background-color: $primary-color;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    > .icon {
      color: $white;
      height: 30px;
      width: 30;
    }
  }

  > .title {
    color: $primary-color;
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
    max-width: 245px;
  }

  > .text {
    color: $text;
    text-align: start;
    font-size: 16px;
    margin-top: 32px;
  }

  > .button {
    margin-top: auto;
  }

  > .countdown-timer {
    display: flex;
    margin: 20px auto;
    font-size: 18px;
    font-weight: 600;
  }
}
