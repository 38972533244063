@import 'styles';

.otp-input-component {
  column-gap: 16px;
  display: flex;
  grid-column-gap: 16px;
  margin: 0 auto;
  max-width: 330px;
  width: 100%;

  > .otp-input {
    border-radius: 10px;
    border: 1px solid $dusty-gray;
    font-weight: 600;
    height: 42px;
    text-align: center;
    width: 100%;

    &:focus {
      border: 1px solid $primary-color;
    }
  }
}
