@import 'styles';

.documents-page {
  @include container;

  padding: 24px 16px 24px;

  > .return {
    color: $text;
    display: flex;
    font-size: 16px;
    font-weight: 600;

    > .icon {
      color: $text-light;
      height: 24px;
      margin-right: 8px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    margin-top: 16px;
  }

  > .card {
    margin-top: 8px;
    min-height: 522px;

    > .tabs {
      border-bottom: 1px solid $light-gray;
      display: flex;
      margin-bottom: 16px;

      @include for-tablet-landscape-up {
        margin-bottom: 64px;
      }

      > .tab {
        color: $text-light;
        flex: 1;
        font-size: 14px;
        line-height: 1.14;
        padding-bottom: 6px;
        position: relative;

        @include for-tablet-landscape-up {
          flex: initial;
          min-width: 192px;
          padding: 16px;
        }

        &.-active {
          color: $text;
          font-weight: 600;

          &:after {
            background-color: $active-tab;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            bottom: 0;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }

    > .button {
      color: $primary-color;
      display: block;
      margin: 8px auto 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
