@import 'styles';

.phone-confirmation-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;

  > .title {
    color: $text;
    display: block;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.45;
    text-align: center;
  }

  > .subtitle {
    color: $text-light;
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 16px;
    text-align: center;
  }

  > .card {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    margin: 24px 0;

    > .title {
      font-size: 14px;
      font-weight: 500;
      color: $text;
    }

    > .text {
      font-size: 12px;
      color: $text-light;
    }
  }

  > .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    > .content {
      > .label {
        color: $text;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
      }

      > .input {
        margin-top: 8px;
      }

      > .timer {
        align-items: center;
        display: flex;
        margin: 24px 0 16px;

        > .button {
          padding: 0 8px;
          width: auto;
        }

        > .text {
          color: $text-light;
          font-size: 10px;
          margin-left: 16px;
        }
      }

      > .message {
        color: $green;
        font-size: 12px;
        line-height: 1.33;
      }
    }
  }

  > .button {
    font-size: 16px;
    padding: 8px;
  }
}
