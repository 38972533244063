@import 'styles';

.address-update-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;

  > .title {
    color: $text;
    display: block;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 33px;
    text-align: center;
  }

  > h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
  }

  > .subtitle {
    color: $text-light;
    display: block;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    text-align: center;
  }

  > .info {
    color: $text-light;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    line-height: 15px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 5px;

    > .bolder {
      margin: 0 3px;
    }

    svg {
      height: 12px;
      margin-right: 10px;
      width: 12px;
    }
  }

  > .card {
    display: flex;
    flex-direction: column;
    padding: 16px 14px;
    margin: 18px 0;

    > .title {
      font-size: 12px;
      font-weight: 500;
      color: $text-light;
      > .text {
        color: $text-light;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  > .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    > .content {
      margin-bottom: 15px;
      > .label {
        color: $text;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
      }

      > .input {
        margin-top: 15px;

        & + .input {
          margin-top: 16px;
        }
      }
      > .message {
        color: $red;
        font-size: 12px;
        line-height: 1.33;
        margin-top: 16px;
      }
    }
  }
}
