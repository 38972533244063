@import 'styles';

.signup-initial {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  > .text {
    color: $text-light;
    font-size: 16px;
    margin-top: 16px;
    text-align: center;
  }

  > .label {
    color: $text;
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
  }

  > .input {
    margin-top: 16px;
  }

  > .error {
    color: $red;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
  }

  > .button {
    margin-top: auto;
  }
}
