@import 'styles';

.document-line-component {
  align-items: center;
  background: $light-gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 8px;
  padding: 8px 16px;
  text-align: center;

  @include for-tablet-landscape-up {
    flex-direction: row;
    padding: 16px;
    text-align: start;
  }

  &:nth-child(2) {
    > .wrapper {
      > .date,
      > .value,
      > .tag,
      > .contract {
        position: relative;

        &:after {
          bottom: 50%;
          color: $text;
          display: none;
          font-size: 12px;
          font-weight: 600;
          left: 0;
          position: absolute;
          transform: translateY(-44px);

          @include for-tablet-landscape-up {
            display: block;
          }
        }
      }
      > .value {
        &:after {
          content: 'NOME';
        }
      }
      > .tag {
        &:after {
          content: 'DESCRIÇÃO';
        }
      }
    }
  }

  > .wrapper {
    color: $text-light;
    flex: 1;

    @include for-tablet-landscape-up {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    > .field {
      font-size: 16px;

      @include for-tablet-landscape-up {
        flex: 2;
      }
    }
  }

  > .actions {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;

    @include for-tablet-landscape-up {
      flex: 1;
    }

    > .icon {
      color: $svg-color;
      height: 24px;
      width: 24px;

      @include for-tablet-landscape-up {
        display: none;
      }
    }

    > .code-button {
      margin-right: 10px;
    }

    > .button {
      display: flex;
      max-width: 160px;
    }
  }
}
