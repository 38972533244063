@import 'styles';

$disabled-button-background: #e5e5e5;

.contact-update-confirmation-stage {
  @include modal-animation;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .title {
    color: $input-focus-label-color;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 96px;
  }

  > .description {
    color: $tundora;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 65%;
  }

  > .container-input {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 0 32px;
    max-width: 100%;
    width: 384px;

    > .input-code {
      border-radius: 5px;
      border: 1px solid $tundora;
      margin-bottom: 12px;
      max-height: 38px;
      width: 100%;

      > input {
        height: 35px;
        padding: 0 11px;

        &:focus-within {
          padding-top: 0;
        }

        &::placeholder {
          color: #b8b8b8;
        }
      }
    }

    > .input-message {
      color: $tundora;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
    }
  }

  > .container-buttons {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 0 33px;
    position: relative;
    width: 100%;

    > .error {
      color: $red;
      font-size: 14px;
      position: absolute;
      top: -20px;
    }

    > .button {
      font-size: 16px;
      font-weight: 600;
      height: 40px;
      width: 44%;

      > .time-left {
        display: flex;
        align-items: center;

        > svg {
          animation: rotation 2s infinite linear;
          height: 20px;
          width: 20px;
          display: unset;
          margin-right: 8px;
        }
      }

      &:disabled {
        background-color: $disabled-button-background;
      }
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
