@import 'styles';

.cancel-confirmation {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin: 24px 0px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .info-confirmation {
      background: $wild-sand;
      border-radius: 10px;
      padding: 16px 12px;

      > .text {        
        font-size: 16px;
        font-weight: 400;
      }

      > .margin {
        margin-top: 15px;
      }

      > .container-reschedule {
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 12px;
        flex-direction: column;

        > .row {
          display: flex;
          margin-top: 10px;

          > .info-title {
            margin-right: 8px;
          }
        }
      }

      > .reference {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        > .text {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }

  > .links {
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
