@import 'styles';

.unlock-account-error {
  @include drawer-content;

  > .round {
    align-items: center;
    background-color: $red;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    > .icon {
      color: $white;
      height: 24px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin: 24px 0 16px;
    text-align: center;
    white-space: pre-wrap;
  }

  > .subtitle {
    color: $text-light;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 24px;
    text-align: center;
    white-space: pre-wrap;
  }
}
