@import 'styles';

$status-color: #6d6767;

.subscription-card-component {
  @include for-tablet-portrait-up {
    display: flex;
  }

  > .content {
    flex: 1;

    > .header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      > .title {
        color: $text-color-shade;
        font-size: 16px;
        font-weight: bold;
      }

      > .tag {
        @include for-tablet-portrait-up {
          display: none;
        }
      }
    }

    > .contract {
      align-items: center;
      display: flex;
      margin-top: 8px;

      @include for-tablet-portrait-up {
        justify-content: space-between;
      }

      > .text {
        color: $text;
        font-size: 14px;
        font-weight: 600;
      }

      > .tag {
        display: none;

        @include for-tablet-portrait-up {
          display: block;
        }
      }
    }

    > .address {
      color: $text-light;
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;

      @include for-tablet-portrait-up {
        margin-top: 16px;
      }
    }

    > .connection-status {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-top: 16px;

      > .round {
        border-radius: 50%;
        height: 10px;
        margin-right: 8px;
        width: 10px;
      }

      > .status {
        color: $status-color;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  > .action {
    flex: 1;
    margin-top: 16px;
    flex-direction: column;

    @include for-tablet-portrait-up {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}
