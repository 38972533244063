@import 'styles';

.pending-plan-component {
  align-items: flex-end;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  margin-top: 24px;
  padding: 16px;

  > .wrapper {
    flex: 1;

    > .tag {
      background-color: rgba(19, 62, 101, 0.49);
      display: unset;
    }

    > .title {
      color: $text;
      font-size: 16px;
      font-weight: 600;
      line-height: 0.94;
      margin: 12px 0;
    }

    > .text {
      color: $text;
      font-size: 14px;
      line-height: 1.57;
    }
  }

  > .icon {
    flex: 0.1;
    height: 32px;
    width: 32px;
  }
}
