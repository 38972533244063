@import 'styles';

.modem-await {
  @include drawer-content;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .step {
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 8px;

      > .step-number {
        align-items: center;
        background-color: $primary-color;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-size: 12px;
        font-weight: 700;
        height: 24px;
        justify-content: center;
        width: 24px;
      }

      > .step-text {
        color: $primary-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;

    > .image {
      width: 141px;
    }
  }
}
