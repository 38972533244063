@import 'styles';

$card-shadow: 0px 2px 2px 0px rgba($black, 0.1);
$card-border: #f0f0f0;

.card-component {
  background-color: $white;
  border-radius: 8px;
  box-shadow: $card-shadow;
  padding: $card-padding;
  border: $card-border solid 1px;

  &.-thin {
    padding: calc(#{$card-padding} / 2);
  }
}
