@import 'styles';

.address-change-request-summary-component {
  @include drawer-content;

  padding: 25px;

  > .title {
    color: $black;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 24px;
    text-align: center;
  }

  > .summary-container {
    background-color: $wild-sand;
    border-radius: 10px;
    color: $mine-shaft;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 12px;

    @include desktop {
      font-size: 14px;
    }

    > .request-text {
      margin-bottom: 16px;
    }

    > .new-address {
      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    > .booking-data-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      margin-top: 8px;

      .booking-data-label {
        display: block;
        margin-bottom: 10px;
      }

      > .shift-container {
        margin-right: 35px;
      }
    }

    > .additional-info-container {
      display: flex;
      font-size: 10px;
      font-style: italic;
      margin-bottom: 16px;

      @include desktop {
        font-size: 12px;
      }

      > .icon {
        flex-shrink: 0;
        height: 12px;
        margin-right: 4px;
        margin-top: 2px;
        width: 12px;

        &.-red path {
          fill: $sunset-orange;
        }

        &.-primary path {
          fill: $primary-color;
        }
      }

      > .warning {
        color: $sunset-orange;
      }
    }
  }

  > .request-button {
    margin: 15px 0;
  }
}
