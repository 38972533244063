@import 'styles';

.virtual-support-info {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin-top: 24px;
      padding: 0px 30px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .subtitle {
      color: $text;
      font-size: 16px;
      text-align: left;
      margin: 18px auto;
    }

    > .card {
      align-items: center;
      border: 2px solid transparent;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      padding: 16px;

      > .card-content {
        display: flex;
        flex-direction: column;
        
        > .tag {
          align-items: center;
          background-color: $primary-color;
          border-radius: 30px;
          color: $white;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          justify-content: center;
          margin-bottom: 5px;
          max-width: max-content;
          padding: 2px 12px;
        }
        
        > .text {
          color: $text;
          font-size: 14px;
          line-height: 1.71;
          text-transform: capitalize;
        }
      }

      & + .card {
        margin-top: 8px;
      }

      &.-selected {
        border-color: $primary-color;
      }

      &.-error {
        border-color: $red;
      }
    }
  }
}
