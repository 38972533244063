.context-loader-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  .loader-img {
    max-width: 250px;
    min-width: 100px;
    animation: pulse 1s linear infinite;
  }

  &.-fixed {
    position: fixed;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
