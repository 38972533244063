@import 'styles';

.days-to-breach-agreement-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 19px;

  > .title {
    color: $mine-shaft-darker;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 9px;
  }

  > .info {
    font-size: 14px;
    line-height: 25px;
    margin: 24px 47px 36px;
    text-align: justify;
    white-space: pre-line;

    > br {
      content: '';
      display: block;
      margin-bottom: 25px;
    }
  }

  > .button {
    font-size: 14px;
    height: 35px;
    width: 374px;
  }
}
