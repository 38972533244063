@import 'styles';

.os-scheduling {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin-top: 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .text {
      color: $text;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 5px;
      margin-top: 32px;
    }

    > .service-period {
      margin-top: 32px;

      > .text {
        color: $text;
        font-size: 16px;
        font-weight: 400;
      }

      > .card {
        border: 2px solid transparent;
        cursor: pointer;
        margin-top: 10px;
        padding: 16px;

        > .text-card {
          color: $text;
          font-size: 14px;
          line-height: 1.71;
        }

        &.-selected {
          border-color: $primary-color;
        }
      }

      .time-frame {
        margin: 15px 0;
      }

      .time-frame-select {
        color: unset;
        margin-top: 0;
      }
    }
  }

  > .links {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
