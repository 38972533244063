@import "styles";

.messages-component {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .empty {
    margin-top: 20px;

    > .icon {
      height: 56px;
      margin: 0 auto;
      width: 56px;
    }
  
    > .subtitle {
      color: $text-light;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      margin-top: 16px;
      text-align: center;
    }
  }



  > .card {
    cursor: pointer;
    margin-top: 15px;

    > .item {
      color: $gray;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      svg {
        height: 16px;
        width: 16px;
      }

      > .title {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 12px;
        max-width: 250px;
      }

      > .oval {
        background-color: $red;
        border-radius: 50%;
        height: 16px;
        margin: 0 0 8px 56px;
        width: 16px;
      }
    }

    > .date {
      font-size: 10px;
      text-align: right;
    }
  }

  > .button {
    color: $primary-color;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin: 12px auto 0;
  }

  > .read {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 8px 8px 8px 16px;
  }
}
