@import 'styles';

.reschedule-installation-date {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > .reschedule-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .reschedule-title {
      color: $black;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 17px;
      text-align: center;
    }
  
  > .reschedule-text {
      color: $text-dark;

    }
  
  > .select-date {
    margin-bottom: 40px;
  }
  
  > .reschedule-info-date {
      margin-bottom: 30px;
    }

  > .reschedule-card {
    align-items: center;
    border: 2px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 16px;
    width: 100%;
    margin: 10px 0;

    > .card-content {
      display: flex;
      flex-direction: column;

      > .card-text {
        color: $text;
        font-size: 14px;
        line-height: 1.71;
        text-transform: capitalize;
      }
    }
  }
  }

}
