@import '../../styles/';

.input-component {
  color: $gray;
  display: block;
  position: relative;

  > .text {
    color: $silver;
    font-size: 14px;
    margin-left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  }

  > .icon {
    cursor: pointer;
    position: absolute;
    height: 24px;
    width: 24px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  > .input {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-gray;
    color: inherit;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s ease-in-out 0s;
    width: 100%;

    &::placeholder {
      color: transparent;
    }

    &.-small {
      height: 32px;
      padding: 8px 16px;
    }

    &.-normal {
      height: 48px;
      padding: 8px 16px;
    }

    &.-hasNoLabel {
      &::placeholder {
        color: $gray;
      }
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        border-color: $primary-color;
      }
    }
  }

  &:focus-within,
  &.-hasValue {
    &.-small {
      > .text {
        margin-left: 8px;
        font-size: 10px;
        top: 8px;
      }
    }

    &.-normal {
      > .text {
        font-size: 12px;
        top: 14px;
      }
    }

    > .text {
      color: $input-focus-label-color;
      font-size: 12px;
      top: 14px;
    }

    > .input {
      padding-top: 22px;

      &.-hasNoLabel {
        padding-top: 8px;
      }

      &::placeholder {
        color: $gray;
      }
    }
  }

  &.-error {
    > .text {
      color: $red;
    }

    > .error {
      color: $red;
      font-size: 10px;
    }

    > .input {
      border-color: $red;

      &:hover,
      &:focus {
        border-color: $red;
      }
    }
  }
}
