@import 'styles';

.ura-page {
  @include container;

  padding: 16px 8px;

  > .logo {
    height: 41px;
    width: 160px;
  }

  > .alert {
    margin: 8px auto 0;
    max-width: 592px;
  }

  > .form {
    margin: 50px auto 0;
    max-width: 550px;
    text-align: center;

    > .title {
      color: $text;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.33;
    }

    > .subtitle {
      color: $text;
      font-size: 17px;
      font-weight: normal;
      margin-top: 8px;
    }

    > .input {
      margin-top: 24px;
    }

    > .button {
      margin-top: 32px;
    }

    > .radios {
      display: flex;
      justify-content: center;
      align-items: center;
      > input {
        margin-left: 15px;
        margin-right: 5px;
        font-size: 16px;
      }
    }
  }
  .radios-wrapper {
    line-height: 20px;
    margin: 0 6px;

    .radio-item {
      cursor: pointer;
      display: inline-block;
      margin-left: 6px;

      &:first-child {
        margin: 0;
      }

      &:hover {
        .label-number {
          opacity: 0.5;
        }
      }

      .input {
        display: none;

        &:checked {
          + .label-number {
            background: $primary-color;
            color: $white;
          }
        }
      }

      .label-number {
        background: $light-gray;
        border-radius: 4px;
        color: white;
        display: block;
        font-size: 16px;
        font-weight: 500;
        height: 42px;
        line-height: 42px;
        pointer-events: none;
        text-align: center;
        transition: 300ms ease-in-out;
        width: 42px;
      }
      //TODO: @otto.gorl 24/02/2022 Abstrair as cores hardcoded em um gradiente css
      .number-0 {
        background: #e94213;
      }

      .number-1 {
        background: #ed5e0e;
      }

      .number-2 {
        background: #f27a0b;
      }

      .number-3 {
        background: #f79508;
      }

      .number-4 {
        background: #fcb004;
      }

      .number-5 {
        background: #fdc900;
      }

      .number-6 {
        background: #d4c30c;
      }

      .number-7 {
        background: #aaba18;
      }

      .number-8 {
        background: #81b327;
      }

      .number-9 {
        background: #57ab32;
      }

      .number-10 {
        background: #2aa340;
      }
    }
  }
}
