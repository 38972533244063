@import 'styles';

.phone-add-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.45;
    text-align: center;
  }

  > .subtitle {
    color: $text-light;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 16px;
    text-align: center;
  }

  > .label {
    color: $text;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 32px;
  }

  > .input {
    margin-top: 28px;
  }

  > .button {
    margin-top: auto;
  }
}
