@import 'styles';

$light-gray-border: #f7f7f7;

.faq-initial-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 35px;
    text-align: center;
  }

  > .subtitle {
    font-weight: 500;
  }

  > .search-input-container {
    display: flex;
    margin-bottom: 32px;
    margin-top: 24px;
    position: relative;
    width: 100%;

    > .input {
      width: 100%;
    }

    > .icon {
      bottom: 0;
      height: 13px;
      margin: auto 0;
      pointer-events: none;
      position: absolute;
      right: 17px;
      top: 0;
    }
  }

  > .categories-container {
    display: grid;
    gap: 40px;
    grid-template-columns: 100px 100px;
    margin: 32px auto 0;

    > .category-button {
      border-radius: 4px;
      border-top: solid 1px rgba($gray, 0.05);
      box-shadow: 0px 2px 2px 0px rgba($black, 0.25);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      height: 100px;
      justify-content: space-between;
      padding: 8px;
      text-align: start;
      width: 100px;

      > .icon-container {
        color: $primary-color;
      }
    }
  }
}
