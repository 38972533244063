@import 'styles';

.virtual-support-info {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    > .round {
      align-items: center;
      background-color: $primary-color;
      border-radius: 50%;
      display: flex;
      height: 64px;
      justify-content: center;
      margin: 0 auto;
      width: 64px;

      > .icon-info {
        color: $white;
        height: 40px;
        width: 40px;
        align-self: center;
      }
    }

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin-top: 24px;
      text-align: center;
      white-space: pre-wrap;
      padding: 0px 30px
    }

    > .card-infos {
      background-color: $wild-sand;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      
      > .tag-status {
        background-color: $primary-color;
        max-width: max-content;
        color: $white;
        padding: 2px 12px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 30px;
        margin-bottom: 5px;

        > .status {
          font-size: 14px;
        }
      }
      > .infos {
        font-size: 14px;
        font-weight: 500;
      
        > .info {
          margin-top: 3px;

          > span {
            font-weight: 400;
          }
        }        
      }

    }
  }

  > .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
