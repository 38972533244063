@import 'styles';

.calls-history-component {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .icon {
    height: 56px;
    margin-top: 24px;
    margin: 0 auto;
    width: 56px;
  }

  > .message {
    color: $text-light;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    margin-top: 16px;
    text-align: center;
  }

  > .text {
    color: $text;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
    margin-bottom: 10px;
    margin-top: 24px;
  }

  > .form {
    align-items: center;
    display: flex;
    margin-bottom: 25px;

    > .text {
      flex: 0.1;
      margin: 0 8px;
    }

    > .input {
      flex: 1;
    }

    > .button {
      flex: 0.5;
      margin-left: 8px;
    }
  }

  > .card {
    margin-top: 16px;
    padding: 8px 20px;

    > .text {
      color: $text;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
    }

    > .date {
      color: $text-light;
      font-size: 12px;
    }

    & + .card {
      margin-top: 8px;
    }
  }
}
