@import 'styles';

.phone-list-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > .empty {
    color: $text-light;
    text-align: center;
  }

  > .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 56px);

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.45;
      text-align: center;
    }

    > .section {
      padding: 16px 2px;

      &:nth-of-type(2) {
        overflow-y: auto;
      }

      > .title {
        color: $text;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
      }

      > .card {
        margin-top: 16px;
        padding: 16px 8px 16px 20px;

        > .content {
          align-items: center;
          display: flex;
          justify-content: space-between;

          > .info {
            display: flex;
            flex-direction: column;

            > .phone {
              color: $text;
              font-size: 14px;
              font-weight: 500;
            }

            > .not-confirmed {
              color: $red;
              font-size: 12px;
              font-weight: 500;
            }
          }

          > .actions {
            display: flex;
            align-self: flex-start;

            > .delete {
              color: $text-light;
              cursor: pointer;
              height: 24px;
              width: 24px;
            }

            > .edit {
              color: $text-light;
              cursor: pointer;
              height: 24px;
              margin-left: 10px;
              width: 24px;
            }
          }
        }

        > .button {
          margin-top: 24px;
          padding: 8px;
          width: auto;
        }

        & + .card {
          margin-top: 8px;
        }
      }
    }

    > .button {
      font-size: 16px;
      padding: 8px;
    }
  }

  > .button-component {
    min-height: $button-large-height;
  }
}
