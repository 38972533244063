@import 'styles';

.email-success-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > .content {
    > .round {
      align-items: center;
      background-color: $green;
      border-radius: 50%;
      display: flex;
      height: 64px;
      justify-content: center;
      margin: 0 auto;
      width: 64px;

      > .icon {
        color: $white;
        height: 42px;
        width: 42px;
      }
    }

    > .title {
      color: $text;
      display: block;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 1.45;
      margin-top: 16px;
      text-align: center;
    }

    > .subtitle {
      color: $text-light;
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      margin-top: 16px;
      text-align: center;
    }
  }
}
