@import 'styles';

$helper-text-color: #4c4c4c;
$helper-text-size: 10px;

.modem-edit {
  @include drawer-content;

  @include for-tablet-landscape-up {
    display: flex;
    flex-direction: column;
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  > .text {
    color: $text;
    font-size: 16px;
    font-weight: 600;
    margin-top: 14px;
  }

  > .input {
    margin-top: 16px;
  }

  > .error {
    color: $red;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
  }

  > .helper-text-container {
    display: flex;
    margin-top: 20px;

    > .icon {
      color: $primary-color;
      flex-shrink: 0;
      height: 13px;
      margin-right: 5px;
      width: 13px;
    }

    > .text {
      font-size: $helper-text-size;
      color: $helper-text-color;

      > br {
        display: block;
        content: '';
        margin-top: $helper-text-size;
      }
    }
  }

  > .button {
    flex-shrink: 0;
    margin-top: 24px;
  }
}
