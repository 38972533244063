@import 'styles';

.holder {
  display: flex;
  justify-content: center;
  padding-top: 30px;

  .modal-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    > .title {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    > .container-qr-code {
      align-items: center;
      border-radius: 10px;
      border: 1px solid $black;
      display: flex;
      flex-direction: column;
      height: 240px;
      justify-content: center;
      margin: 16px 0;
      width: 240px;

      > .holder-code {
        height: 208px;
        width: 208px;
      }
    }

    > .description {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 40px;
      text-align: center;
      width: 100%;
      word-break: break-all;
    }

    > .container-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;

      > .button {
        border-radius: 8px;
        height: 48px;

        > .icon {
          height: 16px;
          width: 16px;
        }
      }

      > .copy {
        margin-bottom: 16px;
      }
    }
  }
}
