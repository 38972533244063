@import 'styles';

.card-component-refer-friend {
  cursor: default;
  display: flex;
  flex-direction: column;

  > .right-upper-menu {
    align-self: self-end;
    max-width: 18px;
    position: absolute;

    svg {
      color: $primary-color;
      cursor: pointer;
    }
  }

  > .wraper-content {
    @include for-tablet-portrait-up {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    > .header {
      align-items: center;
      background: $svg-background;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px;

      @include for-tablet-portrait-up {
        height: 44px;
        margin-bottom: 20px;
        width: 44px;
      }

      > .icon {
        color: $svg-color;
        height: 18px;
        width: 18px;
      }
    }

    > .content {
      @include for-tablet-portrait-up {
        margin-left: 16px;
        flex: 1;
      }

      > .title {
        color: $text-color-shade;
        display: none;
        font-size: 16px;
        font-weight: bold;

        @include for-tablet-portrait-up {
          display: block;
        }
      }

      > .text {
        color: $text;
        font-size: 14px;
        margin-top: 8px;
      }
    }

    > .action {
      align-self: flex-end;
      justify-content: center;
      width: 100%;

      @include for-tablet-portrait-up {
        display: flex;
      }

      > .button {
        color: $action-button-text-color;
        max-width: 148px;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 8px;

        @include for-tablet-portrait-up {
          align-self: center;
          margin: 0 0 0 8px;
        }

        .loader-component {
          width: 20px;
        }
      }

      > .button-coupon {
        align-items: center;
        border-radius: 5px;
        border: solid 1px $primary-color-shade;
        color: $black;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 32px;
        justify-content: center;
        margin-top: 8px;
        width: 148px;

        > .text-coupon {
          font-weight: 600;
          flex: 1;
          text-align: center;
        }

        svg {
          cursor: pointer;
          height: 15px;
          margin-right: 8px;
          width: 15px;
        }

        @include for-tablet-portrait-up {
          align-self: center;
          margin: 0 0 0 8px;
        }
      }
    }
  }
}
