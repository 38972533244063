@import 'styles';

$text-color: #000000;

.auto-support-info {
  @include drawer-content;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  > .container-texts {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > .title,
    > .subtitle,
    > .description {
      color: $text-color;
    }

    > .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 32px;
    }

    > .subtitle {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 27px;
    }

    > .description {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }

  > .container-actions {
    display: flex;
    justify-items: center;
    width: 100%;

    > .button {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
