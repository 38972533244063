@import 'styles';

.contact-update-success-stage {
  @include modal-animation;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: center;
  flex-direction: column;
  height: 100%;

  > svg {
    margin-bottom: 30px;
    width: 73px;
    height: 73px;
  }

  > .description {
    color: $input-focus-label-color;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 16px;
  }

  > .button {
    margin-top: 11px;
    height: 40px;
    max-width: 310px;
    font-size: 16px;
  }
}
