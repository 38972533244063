@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../statics/fonts/poppins/poppins-v15-latin-regular.woff2') format('woff2'),
    url('../../statics/fonts/poppins/poppins-v15-latin-regular.woff') format('woff'),
    url('../../statics/fonts/poppins/poppins-v15-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../statics/fonts/poppins/poppins-v15-latin-500.woff2') format('woff2'),
    url('../../statics/fonts/poppins/poppins-v15-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../statics/fonts/poppins/poppins-v15-latin-600.woff2') format('woff2'),
    url('../../statics/fonts/poppins/poppins-v15-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../statics/fonts/poppins/poppins-v15-latin-700.woff2') format('woff2'),
    url('../../statics/fonts/poppins/poppins-v15-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../statics/fonts/poppins/poppins-v15-latin-800.woff2') format('woff2'),
    url('../../statics/fonts/poppins/poppins-v15-latin-800.woff') format('woff');
}

:root {
  --brand-font: 'Poppins';
}
