@import 'styles';

.financial-line-component {
  align-items: center;
  background: rgba(242, 242, 242, 0.8);
  border-radius: 4px;
  display: flex;
  margin-top: 8px;
  padding: 8px 16px;

  @include for-tablet-landscape-up {
    padding: 16px;
  }

  &:nth-child(2) {
    > .wrapper {
      > .date,
      > .payment-date,
      > .value,
      > .tag,
      > .bill-id {
        position: relative;

        &:after {
          bottom: 50%;
          color: $text;
          display: none;
          font-size: 12px;
          font-weight: 600;
          left: 0;
          position: absolute;
          transform: translateY(-44px);

          @include for-tablet-landscape-up {
            display: block;
          }
        }
      }
      > .value {
        &:after {
          content: 'VALOR';
        }
      }
      > .bill-id {
        &:after {
          content: 'N.º DA FATURA';
        }
      }
      > .date {
        &:after {
          content: 'VENCIMENTO';
        }
      }
      > .payment-date {
        &:after {
          content: 'PAGAMENTO';
        }
      }
      > .tag {
        &:after {
          content: 'STATUS';
        }
      }
    }
  }

  > .wrapper {
    color: $text-light;
    flex: 1;

    @include for-tablet-landscape-up {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      justify-content: flex-end;
    }

    > .value {
      font-size: 16px;

      @include for-tablet-landscape-up {
        flex: 1;
      }
    }

    > .date {
      font-size: 10px;

      &:before {
        content: 'Venc: ';
        display: inline;

        @include for-tablet-landscape-up {
          display: none;
        }
      }

      @include for-tablet-landscape-up {
        flex: 1;
        font-size: 16px;
      }
    }
    > .payment-date {
      font-size: 10px;

      &:before {
        content: 'Pag: ';
        display: inline;

        @include for-tablet-landscape-up {
          display: none;
        }
      }

      @include for-tablet-landscape-up {
        flex: 1;
        font-size: 16px;
      }
    }
    > .bill-id {
      font-size: 10px;

      @include for-tablet-landscape-up {
        flex: 1;
        font-size: 16px;
      }
    }
    > .tag {
      flex: 1;
      margin: 0 8px;
      max-width: 88px;
    }
  }

  > .actions {
    align-items: center;
    display: flex;
    flex: 0.5;
    justify-content: flex-end;

    @include desktop {
      flex: 1.5;
    }

    > .icon {
      color: $svg-color;
      height: 24px;
      margin-right: 5px;
      width: 24px;

      &.download {
        margin-right: 0;
      }

      &:not(.paid-bill-download) {
        @include desktop {
          display: none;
        }
      }
    }

    > .tooltip {
      color: $primary-color-shade;
      height: 24px;
      width: 24px;
    }

    > .code-button {
      margin-right: 10px;
      position: relative;

      > .tooltip {
        background: $primary-color-tint;
        border-radius: 5px;
        bottom: calc(100% + 10px);
        color: white;
        display: block;
        font-size: 12px;
        left: 50%;
        opacity: 0;
        padding: 5px 10px;
        position: absolute;
        transform: translateX(-50%);
        transition: opacity 300ms ease-in-out;
        width: max-content;

        &::before {
          background: $primary-color-tint;
          bottom: -5px;
          content: '';
          height: 10px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%) rotate(45deg);
          width: 10px;
        }
      }
    }

    .pix-button,
    .card-button {
      margin-right: 10px;

      > .icon {
        height: 17px;
        width: 17px;
      }
    }

    > .pix-icon,
    > .card-icon {
      height: 19px;
      padding-right: 2px;
      width: 19px;
    }

    > .clerk {
      max-width: 160px;
    }

    > .button {
      display: none;
      max-width: 128px;
      font-size: 10px;

      @include desktop {
        display: flex;
      }
    }
  }
}
