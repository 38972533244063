@import 'styles';

.refer-friend {
    padding: 25px;
    max-height: calc(98vh - 64px);
    overflow: auto;

    svg {
        height: 40px;
        width: 40px;
        margin: auto;
        margin-bottom: 20px;
        fill: $primary-color-tint;
    }

    > .refer-friend-title {
        color: $text;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.33;
        text-align: center;
        margin-bottom: 30px;

    }

    > .refer-friend-subtitle {
        color: $text;
        font-size: 16px;
        font-weight: 550;
        line-height: 1.5;
        margin-bottom: 17px;
        margin-top: 17px;
    }

    > .refer-friend-text {
        color: $text;
        font-size: 14px;

        &.discount-info {
            margin-top: 20px;
        }

    }

    > .refer-friend-list {
        > .refer-list-item {
            color: $text;
            font-size: 14px;
            line-height: 1.8;

            > .refer-friend-regulation {
                text-decoration: underline;
                color: $text-color;
            }
        }
    }
}
