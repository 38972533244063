@import 'styles';

.bills-card-component {
  > .title {
    color: $text-color-shade;
    font-size: 16px;
    display: inline-flex;
  }

  > .right-upper-menu {
    float: right;
    max-width: 25px;
    svg {
      color: $svg-color;
      cursor: pointer;
    }
  }

  > .current {
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 8px;
    padding: 12px;
    row-gap: 20px;

    @include for-desktop-up {
      flex-wrap: nowrap;
    }

    > .wrapper {
      flex-basis: 33%;

      > .tag {
        margin-top: 8px;
        max-width: 88px;

        @include for-tablet-portrait-up {
          margin-top: 4px;
        }
      }

      > .label {
        font-size: 12px;
        font-weight: 600;
      }

      > .value {
        color: $text-color;
        font-size: 18px;
        font-weight: 500;

        @include for-tablet-portrait-up {
          font-size: 22px;
        }
      }

      > .date {
        color: $text-light;
        font-size: 14px;
        font-weight: 500;

        @include for-tablet-portrait-up {
          margin-top: 4px;
        }
      }

      &.-action {
        display: flex;
        flex-basis: 100%;
        justify-content: space-evenly;

        @include for-desktop-up {
          flex-basis: 33%;
        }

        > .actions {
          display: flex;
          gap: 8px;
          width: 100%;

          > .button {
            align-items: flex-start;
            color: $white;
            display: flex;
            flex-direction: column;
            font-size: 10px;
            font-weight: 500;
            height: 50px;
            padding: 4px;
            text-align: left;
            width: 52px;

            &:hover {
              background-color: $base-button-background-color;
            }

            > .icon {
              height: 16px;
              margin: 0;
              width: 16px;

              svg {
                fill: $white;
                height: 16px;
                width: 16px;
              }
            }

            &.download {
              margin-left: 0;
            }

            &.pix {
              svg {
                height: 13px;
                width: 13px;
              }

              &[disabled] {
                background-color: $base-button-background-color;
              }
            }

            &.credit-card {
              svg {
                height: 13px;
                width: 13px;
              }
            }

            .dots-component {
              .dot {
                & + div {
                  margin-left: 6px;
                }
              }
            }
          }
        }
      }

      &.-status {
        width: 38%;
      }
    }
  }

  > .subtitle {
    color: $text;
    font-size: 14px;
    margin-top: 8px;

    @include for-tablet-portrait-up {
      margin-top: 16px;
    }
  }

  > .wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    > .subtitle {
      color: $text;
      font-size: 14px;
    }

    > .more {
      color: $text-color;
      font-size: 12px;
      font-weight: 500;
    }
  }

  > .margin {
    margin-top: auto;
  }

  > .clerk-warning {
    color: $text;
    font-size: 12px;
    font-style: italic;
    margin-top: 8px;

    @include for-tablet-portrait-up {
      margin-top: 16px;
    }
  }

  > .invoice {
    align-items: center;
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 4px;
    display: flex;
    margin-top: 8px;
    padding: 12px;

    > .wrapper {
      color: $text-light;
      width: 40%;

      @include for-tablet-portrait-up {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        min-width: 210px;
      }

      > .date {
        font-size: 10px;

        @include for-tablet-portrait-up {
          font-size: 16px;
        }
      }
      > .value {
        font-size: 16px;
      }
    }

    > .tag {
      max-width: 88px;
      width: 35%;

      @include for-tablet-portrait-up {
        margin-left: 48px;
      }
    }

    > .actions {
      color: $primary-color-tint;
      display: flex;
      flex: 1;
      justify-content: flex-end;

      > .icon {
        cursor: pointer;
        height: 24px;
        overflow: hidden;
        position: relative;
        width: 24px;

        &:hover {
          overflow: visible;

          .tooltip {
            opacity: 1;
          }
        }

        > .tooltip {
          bottom: calc(100% + 10px);
        }

        &.-download {
          margin-left: 8px;

          @include for-tablet-portrait-up {
            margin-left: 16px;
          }
        }
      }
    }
  }

  .tooltip {
    background: $primary-color-tint;
    border-radius: 5px;
    bottom: 100%;
    color: white;
    display: block;
    font-size: 12px;
    left: 50%;
    opacity: 0;
    padding: 5px 10px;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 300ms ease-in-out;
    width: max-content;

    &::before {
      background: $primary-color-tint;
      bottom: -5px;
      content: '';
      height: 10px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
      width: 10px;
    }
  }

  > .empty {
    display: grid;
    margin-top: 16px;
  }
}
