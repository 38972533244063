@import 'styles';

.modem-finish {
  @include drawer-content;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  .info-container {
    display: flex;
    flex-direction: column;
    height: 288px;
    justify-content: space-between;

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .image-container {
    display: flex;
    justify-content: center;

    > .img {
      max-width: 173px;
      width: 50%;
    }
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
