@import 'styles';

.due-date-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > .content {
    .title {
      color: $text;
      display: block;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 1.45;
      margin-bottom: 15px;
      margin-top: 16px;
      text-align: center;
    }

    > .advise {
      padding: 0 10px;
      text-align: center;

      > .advise-title {
        display: block;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: 2px;
        margin-top: 28px;
      }
    }

    .billet-box {
      margin-top: 10px;
    }

    .infos {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }

    p {
      color: $text;
      font-size: 14px;
      text-align: center;
    }

    .date {
      font-style: italic;
    }

    .radios-wrapper {
      line-height: 50px;
      margin: 0 6px;

      .radio-item {
        cursor: pointer;
        display: inline-block;
        margin-left: 6px;

        &:first-child {
          margin: 0;
        }

        &:hover {
          .label-day {
            background: darken($alto, 8);
          }
        }

        .input {
          display: none;

          &:checked {
            + .label-day {
              background: $label-days-checked-background-color;
              color: $label-days-checked-text-color;
            }
          }
        }

        .label-day {
          background: $light-gray;
          border-radius: 4px;
          color: $text-dark;
          display: block;
          font-size: 16px;
          font-weight: 500;
          height: 42px;
          line-height: 42px;
          pointer-events: none;
          text-align: center;
          transition: 300ms ease-in-out;
          width: 42px;
        }
      }
    }
  }
}
