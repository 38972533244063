@import 'styles';

.application-redirect-page {
  @include container;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 20px;

  > .logo {
    width: 260px;
  }

  > .container-message {
    align-items: center;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 70px 0;
    max-width: 482px;
    padding: 20px 40px 30px 40px;
    width: 95%;

    > .title {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    > .text {
      font-size: 14px;
      margin-bottom: 40px;
      text-align: center;
    }

    > .redirect-button {
      align-items: center;
      background-color: $primary-color;
      border-radius: 5px;
      color: $white;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 100%;
    }
  }
}
