@import 'styles';

.list-bank-slip {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  max-width: 680px;

  > .title {
    margin-bottom: 15px;
  }

  > .list {
    > .item-bank-slip {
      border-bottom: 1px solid;
      margin-bottom: 10px;

      > .text {
        margin-bottom: 15px;

        > a {
          color: $primary-color;
        }
      }

      > .info-text {
        margin-bottom: 20px;

        > .text {
          margin-bottom: 5px;
        }
      }

      > .text {
        margin-bottom: 20px;
      }

      > .table {
        align-items: center;
        align-items: center;
        border: 1px solid;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        > .title {
          flex: 2;
          margin: 0;
          padding-left: 5px;
        }

        > .value-content {
          border-left: 1px solid;
          border-right: 1px solid;
          flex: 2;
          padding-left: 5px;

          > p {
            margin: 0;
          }
        }

        > .date-content {
          flex: 3;
          padding-left: 5px;

          > p {
            margin: 0;
          }
        }
      }

      > .bar-code {
        display: flex;
        margin: 0 auto;
        width: 100%;
      }

      > .button {
        margin: 15px 0;
      }
    }
  }
}
