@import './styles/';

* {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  font-family: var(--brand-font);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: #f3f4f7;
}

img {
  display: block;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

figure,
ul,
menu,
dir {
  margin: 0;
}

ul li,
ul ol {
  list-style: none;
}

svg {
  display: block;
  fill: currentColor;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

a,
a:hover {
  text-decoration: none;
}

a,
a:visited {
  color: currentColor;
}

::-webkit-scrollbar {
  padding: 2px 0;
  max-width: 6px;
  background-color: #ddd;
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
  opacity: 0;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:hover {
  visibility: visible;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
