@import 'styles';

$accordion-border: #e0e0e0;
$vote-color: #979797;
$textarea-border-color: #232323;

.accordion-component {
  border-bottom: 2px solid $accordion-border;
  display: flex;
  flex-direction: column;

  > .header-button {
    align-items: center;
    color: $black;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 18px;
    padding: 10px 22px;
    text-align: start;

    > .icon {
      margin-left: 15px;
      width: 20px;
    }
  }

  > .body-content {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    padding: 0 22px;
    transition: 0.7s ease-in-out;

    ul,
    ol {
      list-style-position: inside;
    }

    li {
      list-style: inherit;
    }

    > .container-review {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 18px 0;

      > .text {
        color: $primary-color;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      > .container-votes {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding-left: 14px;

        > .vote {
          background: transparent;
          border: none;
          color: $vote-color;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &:first-child {
            margin-right: 57px;
          }

          > svg {
            fill: $vote-color;
            width: 22px;
          }

          &.selected {
            color: $primary-color;

            > svg {
              fill: $primary-color;
            }
          }
        }
      }
    }

    > .container-feedback {
      display: flex;
      flex-direction: column;
      width: 100%;

      > .title {
        color: $primary-color;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      > .container-textarea {
        display: flex;
        margin-bottom: 10px;
        position: relative;
        width: 100%;

        > .textarea {
          border-radius: 4px;
          border: 1px solid $textarea-border-color;
          height: 80px;
          margin-bottom: 27px;
          outline: none;
          padding: 5px;
          resize: none;
          width: 100%;
        }

        > .counter {
          bottom: 0;
          display: flex;
          font-size: 12px;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  &.-open {
    > .header-button {
      font-weight: 700;

      > .icon {
        transform: rotate(180deg);
      }
    }

    > .body-content {
      margin-bottom: 15px;
    }
  }
}
