.contact-update-modal {
  overflow-x: hidden;
  padding-top: 44px;
  height: 436px;

  > .container-alert {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 30px;
    position: absolute;
    top: 15px;
    width: 100%;
  }
}
