@import 'styles';

$check-color: #58af24;

.container-error {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 27px;
  width: 100%;

  > .container-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .container-icon {
      height: 53px;
      margin: 54px 0 24px;
      width: 53px;

      svg {
        fill: $red;
      }
    }

    > .title {
      color: $mine-shaft;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;
      text-align: center;
    }

    > .description {
      color: $mine-shaft;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }

  > .container-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .button {
      font-size: 16px;
      font-weight: 500;
      height: 38px;

      &.accept {
        margin-bottom: 10px;
      }
    }
  }
}
