@import 'styles';

.reschedule-confirmation {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > .reschedule-confirmation-info {
    > .reschedule-confirmation-title {
      font-weight: normal;
      margin-bottom: 17px;
      text-align: center;
      font-size: 22px;
  }

    > .reschedule-confirmation-text {
        color: $text-dark;
        margin-top: 10px;

    }

    > .reschedule-overview {
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 12px;
        flex-direction: column;
        background: $wild-sand;
        border-radius: 10px;
        padding: 16px 12px;

        > .info-title {
            margin-top: 10px;
        }
    }
  }

}
