@import 'styles';

.contact-update-email-stage {
  @include modal-animation;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .title {
    color: $input-focus-label-color;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  > .description {
    color: $tundora;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    &.-first {
      margin-bottom: 16px;
    }
  }

  > .container-input {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    max-width: 100%;
    width: 384px;

    > .input-title {
      color: $mine-shaft;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    > .input-email {
      border-radius: 5px;
      border: 1px solid $tundora;
      max-height: 38px;
      width: 100%;

      > input {
        height: 35px;
        padding: 0 11px;

        &:focus-within {
          padding-top: 0;
        }

        &::placeholder {
          color: #b8b8b8;
        }
      }
    }
  }

  > .container-button {
    margin-top: 32px;
    padding: 0 33px;
    position: relative;
    width: 100%;
    width: 376px;

    > .error {
      color: $red;
      font-size: 14px;
      position: absolute;
      top: -20px;
    }

    > .button {
      font-size: 16px;
      font-weight: 600;
      height: 40px;
    }
  }
}
