@import './base-colors';

$primary-color: #06077d;
$primary-color-tint: #344893;
$primary-color-shade: #06077d;
$primary-color-opaque: #06077d;

$select-contract-text: $text;

$select-contract-address-text-color: #5d5d5d;
$action-button-text-color: $black;
$alert-background-color: $primary-color-opaque;
$alert-text-color: $white;
$base-button-hover-text-color: $white;
$notification-color: $primary-color-shade;
$svg-color: $primary-color-shade;
$tag-background-color: $primary-color-opaque;
$tag-text-color: $white;
$text-color-shade: $text;
$text-color: $text;
$base-button-outlined-hover-text-color: $white;
$base-button-background-color: $primary-color-shade;
$base-button-text-color: $white;
$base-button-hover-text-color: $primary-color-shade;
$base-button-hover-background-color: $white;
$base-button-outlined-hover-background-color: $primary-color-shade;
$input-focus-label-color: $black;

$tag-warning: #ffab5e;
$tag-success: $primary-color-opaque;
$tag-success-text: $tag-text-color;
$tag-warning-color-text: $tag-text-color;

$label-days-checked-text-color: $white;
$label-days-checked-background-color: $primary-color-shade;
$base-button-outlined-text-color: $black;
$menu-itens-border: $silver-chalice;
$active-tab: $primary-color-shade;
$my-contract-title-color: $black;
$footer-lock-svg-color: $black;
$password-recovery-svg-color: $black;

$menu-hamburguer-icon: $mine-shaft-darker;

$resend-token-text-color: $white;
