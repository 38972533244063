@import '../../../../styles';

.contract-card-partial {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin: 8px;
  max-width: 600px;
  text-transform: capitalize;
  transition: transform ease-in-out 0.3s;
  width: 100%;

  &:hover {
    transform: scale(1.05);
  }

  > .info {
    > .contract {
      color: $text;
      font-weight: $select-contract-contract-text-weight;
    }

    > .address {
      color: $select-contract-address-text-color;
      font-weight: $select-contract-address-text-weight;
    }
  }
}
