@import 'styles';

$input-placeholder-color: #b8b8b8;

.contact-update-phone-stage {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .title {
    color: $input-focus-label-color;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  > .description {
    color: $tundora;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    &.-middle {
      margin: 16px 0 8px;
    }
  }

  > .container-input {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    max-width: 100%;
    width: 387px;

    > .input-title {
      color: $mine-shaft;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    > .input-phone {
      border-radius: 5px;
      border: 1px solid $tundora;
      margin-bottom: 12px;
      max-height: 38px;
      width: 100%;

      > input {
        height: 35px;
        padding: 0 11px;

        &:focus-within {
          padding-top: 0;
        }

        &::placeholder {
          color: #b8b8b8;
        }
      }
    }

    > .container-checkbox {
      margin-bottom: 24px;
      width: 100%;

      > .input-whatsapp {
        height: 20px;

        > input {
          height: 20px;
          width: 20px;
        }

        > .text {
          color: $mine-shaft;
          font-size: 12px;
          font-weight: 400;
          margin-left: 28px;
        }

        &:focus-within {
          padding-top: 0;

          > .text {
            top: 50%;
          }
        }
      }
    }

    > .container-button {
      padding: 0 33px;
      width: 100%;

      > .button {
        font-size: 16px;
        font-weight: 600;
        height: 40px;
      }
    }
  }
}
