@import 'styles';

.chat-app-container {
  height: 100vh;
  border-radius: 0px;
  position: static;
  width: 100%;

  > .sigma-btn-close {
    display: none;
  }

  > .sigmaHeaderChat {
    display: none;
  }

  > form {
    border: none;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    > .sigma-logo {
      height: 65px;
      margin-bottom: 15px;
    }

    > .sigma-title {
      display: none;
    }

    > .sigma-component-send-message {
      margin-top: auto;
    }
    > .sigma-messages {
      height: unset !important;
    }
    > div {
      .sigma-btn {
        width: 100%;
      }
    }
  }
}
