@import 'styles';

$text-color: #000000;

.invalid-contract-support {
  @include drawer-content;

  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: 100%;
  padding: 33px 27px;

  > .container-icon {
    display: flex;
    justify-content: center;
    padding-top: 7px;

    > .icon {
      color: $primary-color;
      height: 40px;
      width: 40px;
    }
  }

  > .container-texts {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: flex-start;

    > .text {
      font-size: 16px;
      line-height: 28.8px;
    }
  }

  > .container-actions {
    display: flex;
    justify-items: center;
    margin-top: auto;
  }
}
