@import 'styles';

$title-margin-top: 10px;
$title-margin-bottom: 25px;
$title-font-size: 32px;

.modal-component {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &.-open {
    display: flex;
  }

  > .card {
    animation: slide 0.2s ease-in-out 1 forwards;
    border-radius: 4px;
    max-height: $modal-max-height;
    max-width: $modal-max-width;
    position: relative;
    width: 100%;
    z-index: 5000;

    > .icon {
      cursor: pointer;
      height: 24px;
      position: absolute;
      top: 16px;
      width: 24px;
      z-index: 2;

      &.close {
        right: 16px;
      }

      &.back {
        left: 16px;
      }
    }

    > .content {
      > .modal-content {
        max-height: calc(
          #{$modal-max-height} - 2 * #{$card-padding} - #{$title-margin-bottom} - #{$title-margin-top} -
            3 * #{$title-font-size}
        );
        overflow-y: auto;
      }
    }

    &.contactUpdate {
      max-width: 700px;
    }
  }

  > .overlay {
    animation: fade 0.2s ease-in-out 1 forwards;
    background: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 4000;
  }
}

@keyframes slide {
  from {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fade {
  from {
    background: transparent;
  }
  to {
    background: rgba(0, 0, 0, 0.4);
  }
}
