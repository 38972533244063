@import '../../styles/';

@function getColorOrDefault($color, $base-button-hover-text-color) {
  @if ($base-button-hover-text-color) {
    @return $base-button-hover-text-color;
  } @else {
    @return $color;
  }
}

@mixin btn($color) {
  background-color: $color;
  border: 1px solid $color;
  color: $base-button-text-color;

  &:hover {
    &:not([disabled]) {
      background-color: $base-button-hover-background-color;
      color: getColorOrDefault($color, $base-button-hover-text-color);
    }
  }

  &.-outlined {
    background-color: $white;
    color: getColorOrDefault($color, $base-button-outlined-text-color);
    font-weight: $base-font-weight;

    &:hover {
      &:not([disabled]) {
        background-color: getColorOrDefault($color, $base-button-outlined-hover-background-color);
        color: $base-button-outlined-hover-text-color;
      }
    }
  }
}

.button-component {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  transition: background-color ease-in-out 0.3s;
  width: 100%;

  &:hover {
    &[disabled] {
      cursor: not-allowed;
    }
  }

  &[disabled] {
    border: none;
    background-color: $light-gray;
    color: $white;
  }
  &.-normal {
    font-size: 13px;
    font-weight: 600;
    height: 32px;
  }

  &.-large {
    font-size: 16px;
    font-weight: 500;
    height: $button-large-height;
  }

  &.-default {
    background-color: $svg-background;
    border: 2px solid $svg-background;
    color: $primary-color-tint;

    &:hover {
      &:not([disabled]) {
        background-color: $white;
      }
    }
  }

  &.-primary {
    @include btn($base-button-background-color);
  }

  &.-secondary {
    @include btn($orange);
  }

  > .icon {
    height: 20px;
    margin-right: 2px;
    width: 20px;

    @include for-tablet-portrait-up {
      margin-right: 8px;
    }
  }
}
