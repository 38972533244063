@import 'styles';

.negotiation-invoice-list {
  @include drawer-content;
  
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 27px 16px 27px 10px;

  > .title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  > .invoice-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    overflow-y: scroll;

    > .title {
      font-size: 20px;
      font-size: 16px;
      font-weight: 600;
    }

    > .invoice-card {
      border-radius: 5px;
      border: 1px solid $mercury;
      box-shadow: 0px 2px 4px 0px $mercury;
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(2, auto);
      justify-content: space-between;
      padding: 9px 35px 9px 18px;
      row-gap: 4px;

      > .date {
        color: $saphire;
        font-size: 14px;
        font-weight: 500;
      }

      > .value {
        color: $saphire;
        font-size: 23px;
        font-weight: 700;
      }

      > .status-label {
        font-size: 14px;
        font-weight: 600;
      }

      > .tag {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        height: 20px;
        justify-content: center;
        margin-top: 4px;
        width: 70px;
      }
    }
  }

  > .button {
    min-height: 38px;
  }
}
