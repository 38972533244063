@import 'styles';

$weak-password-color: #cd3f56;
$medium-password-color: #fad575;
$force-password-color: #3cb44b;

.container-bars {
  align-items: center;
  display: flex;
  padding-top: 15px;
  width: 100%;

  > .bar {
    background-color: $gallery;
    border-radius: 2px;
    height: 5px;
    margin-right: 8px;
    width: 75px;

    &.active {
      &.weak {
        background-color: $weak-password-color;
      }

      &.medium {
        background-color: $medium-password-color;
      }

      &.strong {
        background-color: $force-password-color;
      }
    }
  }

  > .translate {
    font-size: 10px;
    font-weight: 400;

    &.empty {
      color: $gallery;
    }
  }
}
