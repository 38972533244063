@import 'styles';

.search-ticket {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  max-width: 680px;

  > .text {
    margin: 15px 0;
  }

  .input {
    width: 100%;
  }

  > .button {
    margin-top: 15px;
  }

  > .alert-component {
    margin-bottom: 15px;
  }

  > .alert-content {
    margin-top: 22px;

    > .text {
      > a {
        color: $primary-color;
      }
    }
  }
}
