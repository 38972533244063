@import 'styles';

.generic-card-component.-large {
  @include for-tablet-portrait-up {
    display: flex;
  }

  > .header {
    align-items: center;
    display: flex;

    @include for-tablet-portrait-up {
      align-items: flex-start;
    }

    > .image {
      height: 34px;
      object-fit: contain;
      width: 72px;

      @include for-tablet-portrait-up {
        height: 48px;
        width: 104px;
      }
    }

    > .title {
      color: $text-color-shade;
      font-size: 16px;
      font-weight: bold;
      margin-left: 16px;

      @include for-tablet-portrait-up {
        display: none;
      }
    }
  }

  > .content {
    @include for-tablet-portrait-up {
      margin-left: 16px;
    }

    > .title {
      color: $text-color-shade;
      display: none;
      font-size: 16px;
      font-weight: bold;

      @include for-tablet-portrait-up {
        display: block;
      }
    }

    > .text {
      color: $text;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  > .button {
    margin-top: 8px;
    max-width: 120px;

    @include for-tablet-portrait-up {
      align-self: center;
      margin: 0 0 0 8px;
    }
  }
}
