@import 'styles';

.contract-select-page {
  > .container {
    @include container;

    color: $text;
    padding: 16px 8px;

    > .logo {
      width: $contract-select-logo-width;
    }

    > .wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 50px auto 0;

      > .subtitle,
      > .title {
        font-size: 26px;
        font-weight: 500;
        text-align: center;
      }

      > .title {
        text-transform: capitalize;
      }

      > .subtitle {
        margin-bottom: 24px;
      }
    }
  }
}
