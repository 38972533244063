@import 'styles';

.confirm-schedule-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 24px;

  strong {
    color: $primary-color;

    &.service-order-type {
      text-transform: lowercase;
    }
  }

  > .icon-container {
    align-items: center;
    background-color: $primary-color;
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    margin-bottom: 16px;
    margin-inline: auto;
    width: 60px;

    > .icon {
      color: $white;
      height: 27px;
      width: 27px;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    > .confirm-schedule-address {
      text-transform: capitalize;

      .zip-label {
        text-transform: uppercase;
      }
    }
  }

  > .actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
  }
}
