@import 'styles';

.understand-your-bills-component {
  margin: 32px 0 24px;
  max-height: calc(98vh - 64px);
  overflow: auto;

  .container {
    @include container;
    
    padding: 12px; 

    .topics {
      margin-bottom: 15px;
    }

    li {
      margin-top: 15px;
    }

    .topic {
      color: $text;
      font-size: 16px;
      font-weight: bold;
    }

    .topic-answer {
      color: $text-light;
      font-size: 14px;
      font-weight: normal;
      text-indent: 1em;
    }

    .post-script {
      font-size: 12px;
      margin-bottom: 30px;
    }

    .link {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }

    @include for-tablet-landscape-up {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    > .col {
      @include for-tablet-landscape-up {
        flex: 1;
        padding: 0 8px;
      }
    }
  }
  > .round {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    &.-red {
      background-color: $red;
    }

    &.-green {
      background-color: $green;
    }

    &.-lightBlue {
      background-color: $primary-color-tint;
    }

    > .icon {
      color: $white;
      height: 24px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin: 24px 0 16px;
    text-align: center;
    white-space: pre-wrap;

    &.-paddingBottom {
      padding-bottom: 56px;
    }
  }

  > .subtitle {
    color: $text-light;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 15px;
    text-align: center;
    white-space: pre-wrap;
  }

  > .button {
    margin: 0 auto;
    width: 384px;
  }

  > .info {
    color: $text;
    font-size: 12px;
    line-height: 1.33;
    margin-top: 24px;
    text-align: center;
  }
}
