@import 'styles';

.scheduling-success {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    > .icon {
      background-color: $white;
      border-radius: 50%;
      color: $tag-success;
      height: 64px;
      margin: 0 auto;
      width: 64px;
    }

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 400;
      margin-top: 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .text {
      color: $boulder;
      font-size: 16px;
      font-weight: 400;
      margin-top: 16px;
      text-align: center;
      white-space: pre-wrap;
    }
  }

  > .links {
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
