@mixin container {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
}

@mixin drawer-content {
  animation: slider 0.3s ease-in forwards;
  height: 100%;
  margin-left: 100%;
  overflow-y: auto;
  padding: 32px 16px 24px;
  width: 100%;

  @include for-tablet-portrait-up {
    padding: 25px 32px 24px;
  }
}

@mixin modal-animation {
  animation: slider 0.3s ease-in forwards;
  margin-left: 100%;
  width: 100%;
  overflow: hidden;
}

@keyframes slider {
  100% {
    margin-left: 0;
  }
}
