@import '../../../../styles/';

.dots-component {
  display: flex;
  justify-content: center;
  max-width: 100%;

  &.-white {
    .dot {
      background-color: $white;
    }
  }

  &.-primary {
    .dot {
      background-color: $primary-color;
    }
  }

  &.-secondary {
    .dot {
      background-color: $orange;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;

    & + div {
      margin-left: 10px;
    }
  }

  .dot:nth-child(1) {
    animation: visibility01 1s linear infinite;
  }

  .dot:nth-child(2) {
    animation: visibility02 1s linear infinite;
  }

  .dot:nth-child(3) {
    animation: visibility03 1s linear infinite;
  }
}

@keyframes visibility01 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes visibility02 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes visibility03 {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
