@import 'styles';

.radio-button-component {
  cursor: pointer;
  font-size: 12px;
  position: relative;

  > .label {
    border-radius: 100%;
    border: 2px solid $light-gray;
    display: block;
    float: left;
    height: 16px;
    margin-right: 12px;
    margin-top: 4px;
    position: relative;
    width: 16px;

    &:after {
      background: $primary-color;
      border-radius: 100%;
      content: '';
      height: 8px;
      left: 2px;
      opacity: 0.08;
      pointer-events: none;
      position: absolute;
      top: 2px;
      transform: scale(0);
      transition: all 0.2s ease;
      width: 8px;
    }
  }

  > .input {
    display: none;

    &:checked + .label {
      border-color: $primary-color;

      &::after {
        opacity: 1;
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      }
    }
  }

  &:hover {
    > .label {
      &::after {
        transform: scale(3.6);
      }
    }
  }
}
