@import 'styles';

.phone-card-component {
  @include for-tablet-portrait-up {
    display: flex;
    flex-wrap: wrap;
  }

  > .title {
    color: $text-color-shade;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    width: 100%;

    > .button {
      color: $primary-color-tint;
      font-size: 12px;
      font-weight: 500;
    }
  }

  > .wrapper {
    @include for-tablet-portrait-up {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-evenly;
      margin-top: 4px;
    }

    > .text {
      color: $text;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }

    > .toggle {
      display: block;
      margin-top: 8px;
    }

    &.-actions {
      margin-top: 16px;

      @include for-tablet-portrait-up {
        margin-top: 4px;
      }
    }
  }
}
