@import 'styles';

.unstable-signal {
  @include drawer-content;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .image-container {
    display: flex;
    justify-content: flex-end;
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
