@import 'styles';

.alert-component {
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;

  &.-info {
    background: $alert-background-color;
    color: $alert-text-color;
  }

  &.-success {
    background: $light-green;
    color: $text;

    > .icon {
      color: $green;
    }
  }

  &.-error {
    background: $light-red;
    color: $text;

    > .icon {
      color: $red;
    }
  }

  &.-large {
    padding: 16px 12px;
  }

  &.-small {
    padding: 8px;
  }

  > .icon {
    height: 22px;
    width: 22px;
  }

  > .close {
    cursor: pointer;
    height: 22px;
    width: 22px;
  }

  > .wrapper {
    flex: 1;
    margin-left: 10px;

    > .title {
      font-size: 14px;
      font-weight: 500;

      > .action {
        font-size: 12px;
        margin-left: 8px;
        text-decoration: underline;
      }
    }

    > .text {
      color: $text-light;
      font-size: 14px;
    }
  }
}
