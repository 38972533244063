@import 'styles';

.container-without-options {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 27px;
  width: 100%;

  > .container-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .container-icon {
      margin: 54px 0 24px;

      svg {
        fill: $primary-color;
      }
    }

    > .title {
      color: $mine-shaft;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    > .description {
      color: $mine-shaft;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }

  > .container-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .button {
      font-size: 16px;
      font-weight: 500;
      height: 38px;

      &.accept {
        margin-bottom: 10px;
      }
    }
  }
}
