@import "styles";

.message-details-component {
  @include drawer-content;

  > .title-drawer {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .card {
    color: $text-light;
    line-height: 20px;
    font-size: 14px;
    margin-top: 15px;
  
    > .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 10px;
    }

    > img {
      margin: 15px 0;
      width: 100%;
    }

    > .description {
      text-align: justify;
    }

    > .box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;

      svg {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }

      > .date {
        font-size: 12px;
        text-align: right;
      }

    }
      > .link {
        color: $primary-color;
        display: block;
        margin-top: 15px;
        word-wrap: break-word;
        
        &:hover {
          color: $primary-color-tint;
          text-decoration: underline;
        }
      }
  }
}
