$modal-max-width: 560px;
$modal-max-height: 98vh;
$card-padding: 15px;
$button-large-height: 40px;

$password-recovery-container-margin: 50px auto 0;
$password-recovery-container-width: 396px;
$password-recovery-text-alignment: center;
$password-recovery-logo-width: 160px;
$password-recovery-title-size: 36px;
$password-recovery-subtitle-size: 16px;
