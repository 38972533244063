@import 'styles';

.message-modal {
  margin: 0 auto;
  max-width: 384px;
  text-align: center;

  .message-details-component-modal {
    > .card {
      color: $text-light;
      line-height: 20px;
      font-size: 14px;
      margin-top: 15px;

      > .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 10px;
      }

      > img {
        margin: 15px 0;
        width: 100%;
      }

      > .description {
        line-height: 23px;
        text-align: justify;
        white-space: pre-line;
      }

      > .box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;

        svg {
          cursor: pointer;
          height: 24px;
          width: 24px;
        }

        > .date {
          font-size: 12px;
          text-align: right;
        }
      }
      > .box-link {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;

        > .link {
          color: $primary-color;
          display: block;
          margin-top: 15px;
          text-align: start;

          &:hover {
            color: $primary-color-tint;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .icon {
    color: $gray;
    height: 32px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 32px;
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
  }

  > .text {
    color: $text-light;
    font-size: 16px;
    margin-top: 16px;
  }

  > .button {
    margin: 32px 0;
  }

  > .message-actions {
    display: flex;
    gap: 11px;
    margin: 32px 0;
    min-height: 38px;
  }
}
