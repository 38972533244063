@import 'styles';

.my-calls-component {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .card {
    margin: 24px 0;

    > .text {
      color: $text;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
    }

    > .toggle {
      margin-top: 16px;
    }
  }

  > .button {
    border-radius: 4px;
    border: solid 1px $light-gray;
    color: $text;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 24px 8px 16px;
    position: relative;
    text-align: left;
    width: 100%;

    > .icon {
      color: $light-gray;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }

    & + .button {
      margin-top: 16px;
    }
  }
}
