@import 'styles';

$condominium-option-base-color: #414141;
$condominium-block-border-color: #a9a9a9;

.address-change-condominium {
  @include drawer-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-tablet-portrait-up {
    padding: 64px 15px 24px 32px;
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    margin-bottom: 30px;
    text-align: center;
  }

  > .condominium-list {
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
    max-height: 90%;

    > .subtitle {
      color: $text-light;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    > .condominium-options {
      display: flex;
      flex-direction: column;
      min-height: 90%;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 10px;
      position: relative;

      &.loading {
        @include skeleton-animation;
        transform: none;
      }

      > .condominium-option {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;

        > .condominium-option-label {
          align-items: center;
          display: flex;
          flex: 1;

          .condominium-input {
            height: 24px;
            width: 24px;

            &::after {
              height: 70%;
              left: 3px;
              top: 3px;
              width: 70%;
            }
          }

          > .condominium-name {
            color: $condominium-option-base-color;
            font-size: 14px;
            font-weight: 500;
          }
        }

        > .block-fieldset {
          align-items: center;
          border: none;
          display: flex;
          height: 44px;
          justify-content: space-between;
          position: relative;
          width: 150px;

          > .block-select {
            -webkit-appearance: none;
            background-color: transparent;
            border-radius: 3px;
            border: 1px solid $condominium-block-border-color;
            height: 100%;
            outline: none;
            padding: 0 10px;
            width: 100%;

            > .placeholder {
              display: none;
            }
          }

          > .arrow {
            border: 2px solid $condominium-block-border-color;
            border-right: none;
            border-top: none;
            height: 8px;
            position: relative;
            right: 20px;
            transform: rotate(-45deg);
            width: 8px;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}
