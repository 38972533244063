@import 'styles';

.modem-error-component {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > .icon {
    color: $red;
    height: 40px;
    width: 40px;
  }

  > .title {
    color: $primary-color;
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
    max-width: 245px;
  }

  > .text {
    color: $text;
    text-align: start;
    font-size: 16px;
    margin-top: 32px;
  }

  > .button {
    margin-top: auto;
  }
}
