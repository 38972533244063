@import '../../styles';

.address-card-component {
  overflow-y: auto;

  @include for-tablet-portrait-up {
    display: flex;
    justify-content: space-between;
  }

  > .wrapper {
    flex: 1;

    > .title {
      color: $text-color-shade;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > .label {
      color: $text;
      font-size: 14px;
      font-weight: bold;
      margin-top: 8px;
    }

    > .text {
      color: $text-light;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }

    &.-actions {
      margin-top: 8px;

      @include for-tablet-portrait-up {
        align-items: center;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
