@import 'styles';

.modem-info {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  > .empty {
    color: $text-light;
    font-size: 14px;
    margin-top: 32px;
    text-align: center;

    a {
      color: $primary-color;
    }
  }

  > .modemcard {
    position: relative;

    > .title {
      color: $text;
      font-size: 14px;
      font-weight: 500;
    }

    > .text {
      color: $text-light;
      font-size: 12px;
      margin-bottom: 8px;

      &:first-of-type {
        margin-top: 4px;
      }
    }

    > .icon {
      color: $text-light;
      cursor: pointer;
      height: 24px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }
  }
}
