@import 'styles';

.virtual-support-info {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin-top: 5px;
      margin-bottom: 20px;
      text-align: center;
      white-space: pre-wrap;
    }
  }

  > .massive-waring-card {
    padding: 20px;
    > .card-title {
      font-size: 16px;
      font-weight: 500;
    }
    > .text {
      color: $text;
      font-size: 14px;
      font-weight: 400;
      margin-top: 14px;
      line-height: 23px;
      white-space: pre-line;
    }
  }

  > .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;

    > .button {
      margin-top: 18px;
    }
  }
}
