@import 'styles';

.password-success {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > .success {
    align-items: center;
    background-color: $green;
    border-radius: 50%;
    display: flex;
    height: 64px;
    justify-content: center;
    width: 64px;

    > .icon {
      color: $white;
      height: 40px;
      width: 40px;
    }
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    margin-top: 16px;
  }

  > .text {
    color: $text-light;
    font-size: 16px;
    margin-top: 8px;
  }

  > .button {
    margin-top: auto;
  }
}
