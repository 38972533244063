@import 'styles';

.protocol-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  padding: 16px;

  > .protocol-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  > .protocol-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
}
