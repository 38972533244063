@import 'styles';

.ura-evaluation-modal {
  margin: 0 auto;
  max-width: 384px;
  text-align: center;

  > .round {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 24px auto 0;
    width: 48px;

    > .icon {
      color: $green;
      height: 24px;
      width: 24px;
    }
  }

  > .title {
    color: $text;
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
  }

  > .text {
    color: $text-light;
    font-size: 16px;
    margin-top: 16px;
  }

  > .button {
    margin: 32px 0;
  }
}
