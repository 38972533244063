@import 'styles';

.ribbon {
  --fold-size: 0.5em;
  background-color: #a50101;
  clip-path: polygon(
    calc(100% - var(--fold-size)) calc(100% - var(--fold-size)),
    var(--fold-size) calc(100% - var(--fold-size)),
    0 calc(100% - var(--fold-size)),
    999px calc(100% - var(--fold-size) - 999px),
    calc(100% - 999px) calc(100% - var(--fold-size) - 999px)
  );
  color: $white;
  font-size: 10px;
  left: 0;
  line-height: 1.8;
  padding-bottom: var(--fold-size);
  padding-inline: 1.2lh;
  position: absolute;
  top: 0;
  transform-origin: 100% 100%;
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);

  &.active {
    background-color: #259f34;
  }
}
