@import 'styles';

$border-radius: 20px;

.modem-equipment-list {
  @include drawer-content;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  > .info-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  > .equipment-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    max-height: 100%;
    overflow-y: auto;

    > .equipment-content {
      border-radius: $border-radius;
      box-shadow: 0px 4px 4px 0px #00000040;
      display: flex;
      flex-shrink: 0;
      height: 136px;
      max-width: 344px;
      overflow: hidden;
      width: 100%;

      img {
        border-radius: $border-radius;
        max-width: 190px;
        object-fit: cover;
        width: 50%;
      }

      > .equipment-info {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;

        > .title {
          color: #2d2d2d;
          font-size: 10px;
          font-weight: 500;
        }

        > .name {
          color: #006ebd;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}
