@import 'styles';

.total-negotiation-card {
  display: flex;
  flex-direction: column;
  gap: 7px;

  > .title {
    font-size: 16px;
    font-weight: 600;
  }

  > .value {
    color: $sunset-orange;
    font-size: 32px;
    font-weight: 600;
  }

  > .divider {
    background-color: $mercury;
    border: none;
    height: 1px;
  }
}
