@import 'styles';

.menu-button-partial {
  border-radius: 4px;
  border: solid 1px $menu-itens-border;
  color: $text;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 24px 4px 16px;
  position: relative;
  text-align: left;
  width: 100%;

  &:visited {
    color: $text;
  }

  > .icon {
    color: $menu-itens-border;
    height: 16px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
  }
}
