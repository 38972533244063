@import 'styles';

$iron: #e0e0e1;

.custom-date-picker-component {
  align-items: center;
  background: $white;
  border-radius: 5px;
  border: 1px solid $mercury;
  box-shadow: 2px 2px 4px rgba($black, 0.15);
  display: flex;
  height: 60px;
  justify-content: flex-start;
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  > .icon-calendar {
    fill: $silver-chalice;
    height: 18px;
    pointer-events: none;
    position: absolute;
    right: 25px;
    width: 18px;
  }

  > div {
    width: 100%;
  }

  .input {
    background: $white;
    border-radius: 4px;
    border: 1px solid $white;
    font-size: 14px;
    height: 60px;
    line-height: 140%;
    outline: 0;
    padding: 14px 16px;
    width: 100%;

    &.selected {
      border-color: $primary-color;
    }

    &:focus {
      font-size: 14px;
      padding: 20px 16px 6px;
    }
  }

  &.active {
    label {
      color: $primary-color;
      font-size: 12px;
      top: 20px;
    }

    input {
      color: $primary-color;

      &::placeholder {
        color: $text-light;
      }
    }
  }

  &.error {
    label {
      color: $red;
    }
  }

  label {
    left: 28px;
    line-height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 300ms ease-in-out;
  }

  .react-datepicker {
    .react-datepicker__header {
      background: transparent;
      border: 0;
      padding-top: 7px;
    }

    .react-datepicker__header__dropdown {
      margin: 6px 0;
    }

    .react-datepicker__month {
      border: solid 1px $iron;
      margin-top: 0;
    }

    .react-datepicker__current-month {
      font-size: 15px;
      font-weight: 400;
    }

    .react-datepicker__week {
      border-top: solid 1px $iron;

      &:first-child {
        border: 0;
      }
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      border-left: solid 1px $iron;
      line-height: 20px;
      margin: 0;
      padding: 5px;
      width: 30px;

      &:first-child {
        border: 0;
      }

      &--selected,
      &--keyboard-selected,
      &:hover {
        border-radius: 0;
      }

      &--outside-month {
        opacity: 0.6;
      }
    }

    .react-datepicker__day-name {
      border: 0;
      font-size: 11px;
      line-height: 110%;
    }

    select {
      background: $white;
      border-radius: 4px;
      border: 1px solid $iron;
      outline: 0;
      padding: 3px 8px;

      &.error {
        border-color: $red;
      }

      & + i {
        height: 14px;
        opacity: 0.4;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
      }

      option {
        padding: 5px 0;

        &:hover {
          background-color: transparent;
          color: $primary-color;
        }
      }
    }
  }
}
