@import 'styles';

.duplicate-invoice-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;

  > .icon {
    margin-bottom: 8px;

    > .background {
      align-items: center;
      background-color: $primary-color-tint;
      border-radius: 24px;
      display: flex;
      height: 48px;
      justify-content: center;
      width: 48px;

      > .image {
        height: 24px;
        width: 24px;
      }
    }
  }

  > .title {
    align-items: center;
    display: flex;
    font-size: 18px;
    height: 48px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  > .message {
    height: 72px;
    margin-bottom: 32px;
    width: 408px;
    > .text {
      color: $text-light;
      text-align: center;
    }
  }

  > .button {
    width: 384px;
    margin-bottom: 24px;
  }

  > .warning {
    color: $text;
    font-size: 12px;
    height: 40px;
    line-height: 1.33;
    padding: 0 83px;
    text-align: center;
    width: 536px;
  }
}
