@import 'styles';

.password-reset-page {
  @include container;

  padding: 16px 8px;

  > .logo {
    width: 160px;
  }

  > .alert {
    margin: 8px auto 0;
    max-width: 592px;
  }

  > .form {
    margin: 50px auto 0;
    max-width: 396px;
    text-align: center;

    > .title {
      color: $text;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.33;
    }

    > .subtitle {
      color: $text-light;
      font-size: 16px;
      font-weight: normal;
      margin-top: 8px;
    }

    > .rules {
      background: $light-gray;
      color: $gray;
      font-size: 10px;
      margin-top: 10px;
      padding: 10px;
      text-align: center;
    }

    > .password {
      margin-top: 24px;
    }

    > .confirmation {
      margin-top: 16px;
    }

    > .error {
      color: $red;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
    }

    > .button {
      margin-top: 32px;
    }
  }
}
