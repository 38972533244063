@import 'styles';

.virtual-support-info {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .content {
    > .round {
      align-items: center;
      background-color: $primary-color;
      border-radius: 50%;
      display: flex;
      height: 64px;
      justify-content: center;
      margin: 0 auto;
      width: 64px;

      > .icon-support {
        align-self: center;
        color: $white;
        height: 42px;
        width: 42px;
      }
    }

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 500;
      margin-top: 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .text {
      color: $text;
      font-size: 16px;
      font-weight: 500;
      margin-top: 32px;
    }
    
      > .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 25px;
        text-align: center;
      }
    

    > .links {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: auto;
  
      > .button {
        margin-top: 18px;
      }
    }
  }
}
