@import '../../styles/';

@mixin notificationType($color, $background, $iconColor) {
  & > .Toastify {
    &__progress-bar {
      background: $color;
    }
    &__close-button {
      color: $color;
      opacity: 1;
    }
  }

  & div > div > svg {
    color: $iconColor;

    & > g > path {
      fill: $color;
    }
  }
}

.toast {
  background-color: white;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-size: 14px;

  & > .Toastify__toast-body > div {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    width: 100%;

    & > svg {
      margin-right: 8px;
      width: 40px;
    }
  }

  &__warn {
    @include notificationType(#ffd599, #362100, $tag-warning);
  }

  &__info {
    @include notificationType($primary-color-tint, #08223b, $primary-color);
  }

  &__success {
    @include notificationType(#b7dfb9, #183a18, $tag-success);
  }

  &__error {
    @include notificationType(#fab3ae, #3f100e, $red);
  }
}
