@import 'styles';

.email-delete-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  > .content {
    > .title {
      color: $text;
      display: block;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 1.45;
      margin-bottom: 16px;
      text-align: center;
    }

    > .text {
      color: $text;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
    }

    > .card {
      margin-top: 16px;
      padding: 16px 20px;

      > .text {
        color: $text;
        font-size: 14px;
        line-height: 1.71;
      }
    }
  }
}
