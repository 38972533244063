@import 'styles';

.suspended-contract-warning {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 35px;
  height: 100%;
  justify-content: space-between;
  padding: 24px 33px;

  > .icon {
    color: $primary-color;
    height: 40px;
    margin: auto;
    width: 40px;
  }

  > .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 16px;
    gap: 35px;
    line-height: 28.8px;
  }
}
