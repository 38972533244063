@import 'styles';

.password-recovery-page {
  @include container;

  padding: 16px 8px;

  > .logo {
    width: $password-recovery-logo-width;
  }

  > .alert {
    margin: 8px auto 0;
    max-width: 592px;
  }

  > .form {
    margin: $password-recovery-container-margin;
    max-width: $password-recovery-container-width;
    text-align: $password-recovery-text-alignment;

    > .title {
      color: $text;
      font-size: $password-recovery-title-size;
      font-weight: 500;
      line-height: 1.33;
    }

    > .subtitle {
      color: $text-light;
      font-size: $password-recovery-subtitle-size;
      font-weight: normal;
      margin-top: 8px;
    }

    > .input {
      margin-top: 24px;
    }

    > .container-captcha {
      margin-top: 32px;
    }

    > .button {
      margin-top: 32px;
    }
  }
}
