@import 'styles';

$border-bottom-color: #dbdbdb;

.my-services-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.45;
    margin-bottom: 20px;
    text-align: left;
  }

  .contet-wrapper {
    display: flex;
    flex-direction: column;

    > .subtitle {
      color: $text-light;
      font-size: 16px;
      font-weight: normal;
      line-height: 29px;
      margin-top: 16px;
      text-align: center;
    }

    > .message {
      color: $gray;
    }

    > .header {
      background-color: $white;
      color: $text-light;
      padding-bottom: 10px;
      position: sticky;
      top: 0;
      width: 100%;

      > .column-title {
        color: $text-dark;
        font-size: 12px;
        font-weight: 600;
      }
    }

    > .container-infos {
      border: 1px solid $light-gray;
      box-shadow: 0px 5px 4px -1px rgba($black, 0.25);
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;
      cursor: auto;

      > .container-info {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        &:first-child {
          border-bottom: 1px solid $light-gray;
          margin-bottom: 12px;
          align-items: center;
        }

        > .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > .text {
            color: $text;
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
          }

          > .title {
            color: $text;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 10px;
          }

          > .subtitle {
            color: $text;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
          }
        }

        > .icon {
          color: $gray;
          height: 32px;
          width: 32px;
        }

        > .status {
          padding: 8px;
          background: $dusty-gray;
          border-radius: 24px;
          text-transform: lowercase;
          font-size: 12px;
          color: $tag-text-color;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        > .scheduled {
          background: $green;
        }
      }
    }

    > .scheduled {
      cursor: pointer;
    }

    .grid {
      color: $text-light;
      display: grid;
      grid-template-columns: 22% 1fr 1fr 22%;

      > .column-title {
        font-size: 12px;
        padding-bottom: 10px;
      }

      > .container-info {
        align-items: center;
        display: flex;
        height: 100%;
        height: 55px;
        justify-content: flex-start;
        width: 100%;

        > .info {
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .grid > span {
      border-bottom: 1px solid $light-gray;
      font-size: 10px;

      > strong {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

.custom-drawer {
  padding: 33px 26px 24px;
}
