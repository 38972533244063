@import '../../styles';

.toggle-component {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding-left: 48px;
  position: relative;

  > .input {
    display: none;

    &:checked {
      & ~ .slider {
        background-color: $primary-color;

        &:before {
          transform: translate(24px, -50%);
        }
      }
    }
  }

  > .slider {
    background-color: $light-gray;
    border-radius: 30px;
    height: 16px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease-in-out;
    width: 40px;

    &:before {
      background: $white;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease-in-out;
      width: 14px;
    }
  }
}
