@import 'styles';

.faq-categories-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;

  > .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 35px;
    text-align: center;
  }

  > .subtitle {
    font-weight: 500;
    margin-bottom: 32px;
  }
}
