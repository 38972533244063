@import '../../styles';

.tag-component {
  border-radius: 8px;
  color: $tag-text-color;
  display: block;
  font-size: 10px;
  font-weight: 600;
  padding: 1px 6px;
  text-align: center;
  text-transform: uppercase;

  &.-primary {
    background-color: $tag-background-color;
  }
  &.-secondary {
    background-color: rgba(19, 62, 101, 0.49);
  }
  &.-success {
    background-color: $tag-success;
    color: $tag-success-text;
  }
  &.-warning {
    background-color: $tag-warning;
    color: $tag-warning-color-text;
  }
  &.-light {
    background-color: $silver;
  }

  &.-danger {
    background-color: $sunset-orange;
    color: $black;
  }
}
