@import 'styles';

.my-services-component {
  @include drawer-content;

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .subtitle {
    color: $text-light;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 16px;
    text-align: center;
  }

  > .message {
    color: $gray;
  }

  > .text {
    color: $text;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin: 16px 0;
  }

  > .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8px;
    position: relative;

    > .container-info {
      align-items: center;
      display: flex;

      > .img {
        flex: 0.2;
        margin-right: 16px;
        width: 50px;
      }

      > .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > .title {
          color: $text;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.71;
        }

        > .info {
          color: $text-light;
          font-size: 14px;
          line-height: 1.71;
        }

        > .actions {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          margin-top: 8px;

          > .link {
            align-items: center;
            background: $white;
            border-radius: 4px;
            border: 1px solid #e4e4e4;
            color: $black;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
            justify-content: center;
            width: 114px;

            &.see-details {
              margin-left: 11px;
            }
          }
        }
      }
    }

    & + .card {
      margin-top: 16px;
    }
  }
}
