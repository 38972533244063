@mixin skeleton-animation {
    &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(#d9d9d9, 0) 0,
        rgba(#d9d9d9, 0.5) 50%,
        rgba(#d9d9d9, 0)
    );
    animation: load-skeleton 2s infinite;
    content: '';
    }

    @keyframes load-skeleton {
        100% {
            transform: translateX(100%);
        }
    }
}