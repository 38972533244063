@import '../../styles';

.select-contract-component {
  color: $text-color;
  display: inline-block;
  font-weight: bold;
  position: relative;
  width: 100%;
  z-index: 1;

  > .label {
    color: $select-contract-text;
    font-size: 16px;
    font-weight: 500;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }

  > .icon {
    color: $gray;
    height: 24px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    z-index: -1;
  }

  > .combo {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid $menu-itens-border;
    color: transparent;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 2px;
    text-indent: 82px;
    width: 100%;
    z-index: 1;

    > .option {
      color: $select-contract-text;
      font-weight: 500;
    }
  }
}
