@import 'styles';

.protocol-card {
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px $shadow-dark;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  max-width: 331px;
  padding: 16px;

  &-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  > .protocol-separator {
    border: 1px solid $light-gray;
    height: 0px;
    margin-bottom: 10px;
    width: 314px;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > .protocol-card {
      &-date {
        > span {
          font-weight: 700;
          margin-right: 8px;
        }
      }
      &-status {
        color: $silver-dark;
      }
    }
  }
}
