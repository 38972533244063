@import 'styles';

.response-bank-slip {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  max-width: 680px;

  > .text {
    margin: 15px 0;

    > a {
      color: $primary-color;
    }
  }

  > .button {
    margin-top: 15px;
  }
}
