@import 'styles';

.address-change-status {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 35px;
  justify-content: space-between;

  > .buttons {
    width: 100%;

    > .order-button {
      margin: 15px 0;
    }

    > .back-button {
      margin-top: 15px;
    }
  }

  > .content {
    > .card {
      background: $svg-background;
      border-radius: 10px;
      padding: 15px;
      width: 100%;

      > .status {
        align-items: center;
        background: $primary-color;
        border-radius: 30px;
        color: $white;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 20px;
        justify-content: center;
        margin-bottom: 8px;
        padding: 12px;
        text-transform: uppercase;
        max-width: max-content;
      }

      .label {
        color: $text;
        font-size: 14px;
        font-weight: 500;

        .text {
          color: $text-dark;
          font-weight: normal;
        }
      }

      > .infos {
        display: flex;
        margin-top: 5px;

        > .order-info {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          width: 60%;

          .text {
            color: $text-dark;
            margin-top: 5px;
          }
        }

        > .order-shift {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          width: 43%;

          .text {
            color: $text-dark;
            margin-top: 5px;
          }
        }
      }

      > button {
        margin-bottom: 10px;
      }
    }

    > .icon {
      height: 54px;
      margin-bottom: 24px;
      width: 54px;

      > .green {
        fill: $green;
      }
    }

    > .title {
      color: $black;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 17px;
      text-align: center;
    }

    .subtitle {
      color: $text-dark;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 15px;
      margin-top: 24px;
      white-space: break-spaces;
    }

    .hours {
      color: $text-dark;
      font-size: 12px;
      margin-top: 10px;
    }

    .button-component.-normal {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
