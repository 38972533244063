@import 'styles';

.container-copy {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 59px 30px 59px 30px;

  > .container-alert {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;

    > .icon {
      height: 60px;
      margin-bottom: 12px;
      width: 60px;

      svg {
        fill: $green;
      }
    }

    > .description {
      color: $mine-shaft;
      font-size: 18px;
      font-weight: 500;
    }
  }

  > .container-steps {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    > .step {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &.first {
        margin-bottom: 16px;
      }

      > .number {
        align-items: center;
        background-color: $primary-color;
        border-radius: 50%;
        color: $white;
        display: flex;
        height: 25px;
        justify-content: center;
        margin-right: 16px;
        min-height: 25px;
        min-width: 25px;
        width: 25px;
      }

      > .description {
        color: $mine-shaft;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  > .container-buttons {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    > .button {
      border-radius: 8px;
      height: 48px;
      width: 280px;
    }
  }
}
