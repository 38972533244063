@import 'styles';

.generic-card-component.-small {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  @include for-tablet-portrait-up {
    align-items: initial;
  }

  > .image {
    align-items: center;
    background: $svg-background;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    @include for-tablet-portrait-up {
      height: 44px;
      width: 44px;
    }

    > .icon {
      color: $svg-color;
      height: unset;
      width: unset;
    }
  }

  > .container {
    margin-left: 16px;
    flex: 1;

    > .title {
      color: $text-color-shade;
      font-size: 16px;
      font-weight: bold;
    }

    > .text {
      color: $text;
      display: none;
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 8px;

      @include for-tablet-portrait-up {
        display: block;
      }
    }
  }

  > .action {
    align-self: flex-end;
    display: none;
    justify-content: center;
    width: 100%;

    @include for-tablet-portrait-up {
      display: flex;
    }

    > .button {
      color: $action-button-text-color;
      max-width: 140px;
      text-transform: uppercase;
    }
  }
}
