@import 'styles';

.activation-pending-component {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > .content {
    > .title {
      color: $text;
      display: block;
      font-size: 22px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 33px;
      text-align: left;
    }

    > .subtitle {
      color: $text-light;
      display: block;
      font-size: 16px;
      line-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: left;
    }

    > .card-infos {
      background-color: $wild-sand;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      
      > .tag-status {
        background-color: $primary-color;
        max-width: max-content;
        color: $white;
        padding: 2px 12px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 30px;

        > .status {
          font-size: 14px;
        }
      }
      > .infos {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;

        > .abstract {
          font-weight: 600;
          margin: 15px 0px;
        }
      
        > .info {
          margin-top: 3px;
          margin-bottom: 5px;

          > span {
            font-weight: 400;
          }
        }        
      }
      > .alert {
        display: flex;

        .icon-alert {
          color: $notification-color;
          height: 17px;
          margin-right: 3px;
          width: 17px;
        }

        .alert-schedule {
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
  }
  .links {
    margin-top: auto;
    text-align: center;
    
    > .button {
      margin-top: 18px;
      margin-bottom: 10px;
    }
    
    > .text-info {
      font-size: 12px;
      font-weight: 400;
      color: $mine-shaft;
    }
  }
}
