@import 'styles';

.booking-form-component {
  @include drawer-content;

  padding: 24px 16px;

  > .title {
    color: $black;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 24px;
    text-align: center;
  }

  > .text {
    color: $boulder;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
    text-align: center;
  }

  > .field-title {
    color: $black;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .time-frame-select {
    color: unset;
    margin-bottom: 20px;
    margin-top: 0;
  }
}
