@import 'styles';

.reschedule-success {
  @include drawer-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .content {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .icon {
      background-color: $white;
      border-radius: 50%;
      color: $tag-success;
      height: 64px;
      margin: 0 auto;
      width: 64px;
    }

    > .title {
      color: $text;
      font-size: 22px;
      font-weight: 400;
      margin-top: 24px;
      text-align: center;
      white-space: pre-wrap;
    }

    > .text {
      color: $text;
      font-size: 16px;
      font-weight: 400;
      margin-top: 16px;
      text-align: center;
      white-space: pre-wrap;
    }
  }

  > .button {
    margin-top: 18px;
    cursor: pointer;
  }
}
