@import 'styles';

$animation-time: 0.3s;

.drawer-component {
  background-color: $white;
  bottom: 0;
  max-width: 425px;
  position: fixed;
  right: -100%;
  top: 0;
  transition: right $animation-time ease-in-out;
  width: 100%;
  z-index: 2000;

  &.-open {
    right: 0;
  }

  > .header {
    box-shadow: 0 2px 4px 0 rgba(41, 41, 41, 0.1);
    color: $text;
    display: flex;
    height: 48px;
    padding: 12px 16px;
    width: 100%;

    > .icon {
      cursor: pointer;
      height: 24px;
      width: 24px;

      &.-right {
        margin-left: auto;
      }

      &.-left {
        margin-right: auto;
      }
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    width: 100%;

    > .alert {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include for-tablet-portrait-up {
        left: 50%;
        top: 58px;
        transform: translateX(-50%);
        width: calc(100% - 64px);
      }
    }
  }
}

.drawer-overlay {
  animation: overlay-show $animation-time ease-in forwards;
  background: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@keyframes overlay-show {
  to {
    background: $overlay-shadow;
  }
}
