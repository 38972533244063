@import 'styles';

.container-address-confirmation {
  @include drawer-content;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .container-info {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .container-title {
      display: flex;
      justify-content: center;
      margin-bottom: 22px;
      width: 100%;

      > .title {
        font-size: 20px;
        font-weight: 400;
      }
    }

    > .contract-info {
      font-size: 14px;
      font-weight: 400;

      > .contract {
        color: $primary-color;
        font-weight: bold;
      }
    }

    > .container-card {
      align-items: flex-start;
      border-radius: 5px;
      border: 1px solid $mercury;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      margin: 16px 0 24px;
      padding: 12px;
      width: 100%;

      > .title {
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
      }

      > .info {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    > .instruction {
      font-size: 14px;
      font-weight: 400;
    }
  }

  > .container-buttons {
    width: 100%;

    > .button {
      cursor: pointer;
      width: 100%;
    }

    > .confirm {
      margin-bottom: 16px;
    }
  }
}
