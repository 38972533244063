@import 'styles';

.plan-details-component {
  @include drawer-content;

  > .alert {
    margin-top: 20px;
  }

  > .title {
    color: $text;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
  }

  > .subtitle {
    color: $text;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 6px;
    margin-top: 18px;

    @include for-tablet-portrait-up {
      margin-bottom: 8px;
      margin-top: 24px;
    }
  }

  > .card {
    > .text {
      color: $text;
      font-size: 14px;
      line-height: 1.43;
    }

    > .tv-password-container {
      align-items: center;
      display: flex;

      > .text {
        color: $text;
        font-size: 14px;
        line-height: 1.43;
      }

      > .password {
        color: $text;
        font-family: monospace;
        font-size: 14px;
        line-height: 1.43;

        &.-hidden {
          -webkit-text-security: disc;
        }
      }

      > .icon {
        color: $text-light;
        cursor: pointer;
        margin-left: 20px;
        user-select: none;
        width: 20px;
      }
    }

    &.-flex {
      align-items: center;
      display: flex;
    }

    &.-additional {
      height: 66px;
      margin-top: 8px;
      justify-content: space-between;

      > .container-info {
        align-items: center;
        display: flex;

        > .img {
          margin-right: 10px;
          width: 40px;

          @include tablet {
            margin-right: 15px;
          }
        }

        > .total,
        > .text {
          color: $text;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
        }
      }

      > .container-buttons {
        display: flex;
        flex-direction: column;
        width: 135px;
      }
    }

    &.-contract {
      margin-top: 24px;

      > .content {
        flex: 1;

        > .title {
          color: $my-contract-title-color;
          font-size: 14px;
          font-weight: 600;
          line-height: 0.86;
        }

        > .text {
          color: $text;
          font-size: 12px;
          line-height: 1.33;
          margin: 8px 10px 0 0;
        }
      }

      > .button {
        flex: 0.7;
      }
    }
  }
}
