@import 'styles';

$alert-color: #ee810e;
$alert-background: #fdf2e5;

.password-reset-page {
  @include container;

  padding: 16px 8px;

  > .logo {
    width: 160px;
  }

  > .alert {
    margin: 8px auto 0;
    max-width: 592px;
  }

  > .form {
    margin: 50px auto 0;
    max-width: 396px;
    text-align: center;

    > .title {
      color: $text;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.33;
    }

    > .subtitle {
      color: $text-light;
      font-size: 16px;
      font-weight: normal;
      margin-top: 8px;
    }

    > .warning {
      background: $alert-background;
      border: 3px $alert-color solid;
      color: $text;
      display: flex;
      font-size: 14px;
      gap: 15px;
      margin-top: 8px;
      padding: 10px 25px;
      text-align: left;

      > .icon {
        color: transparent;
        height: 25px;
        width: 70px;

        path {
          stroke: $alert-color;
        }
      }
    }

    > .rules {
      background: $light-gray;
      color: $gray;
      font-size: 10px;
      margin-top: 10px;
      padding: 10px;
      text-align: center;
    }

    > .password {
      margin-top: 24px;
    }

    > .confirmation {
      margin-top: 16px;
    }

    > .error {
      color: $red;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
    }

    > .button {
      margin-top: 32px;
    }

    > .remind-later {
      color: $primary-color;
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;
      padding: 20px;
      text-decoration: underline;
    }
  }
}
